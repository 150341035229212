import { Component, Input } from '@angular/core'
import { MatIcon } from '@angular/material/icon'
import { ParagraphDirective } from '@app-ui/directives'

@Component({
    selector: 'app-notification-bar',
    standalone: true,
    imports: [
        MatIcon,
        ParagraphDirective,
    ],
    templateUrl: './notification-bar.component.html',
    styleUrl: './notification-bar.component.scss',
})
export class NotificationBarComponent {

    @Input({ required: true })
    public type: 'warning' | 'success' | 'danger' | 'info'

}
