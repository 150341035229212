<app-default-layout>
    <div class="feedback-wrapper">
        <div>
            <div class="main-heading">
                <mat-icon class="success-icon">
                    check_circle
                </mat-icon>
                <h1 appHeading>
                    Beste gast, welkom!
                </h1>
            </div>
        </div>

        <div class="timer-container">
            <app-timer [length]="8000" (done)="returnToStart()"></app-timer>
            <p appParagraph isLarge>De volgende gast wordt geholpen in</p>
        </div>
    </div>

</app-default-layout>
