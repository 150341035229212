import { booleanAttribute, Directive, HostBinding, Input } from '@angular/core'

@Directive({
    selector: '[appHeading]',
    standalone: true,
})
export class HeadingDirective {

    @Input({ transform: booleanAttribute })
    public isSmall: boolean = false

    @Input({ transform: booleanAttribute })
    public isLarge: boolean = false

    @Input({ transform: booleanAttribute })
    public isHuge: boolean = false

    @HostBinding('class')
    public get classes(): string {
        let classes: Array<string> = []
        classes.push('dir-heading')
        if (this.isSmall) {
            classes.push('dir-heading__small')
        }
        if (this.isLarge) {
            classes.push('dir-heading__large')
        }
        if (this.isHuge) {
            classes.push('dir-heading__huge')
        }
        return classes.join(' ')
    }

}
