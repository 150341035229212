// the time to wait before retrying a failed request
const RETRY_MILLISECONDS = 30 * 1000;
class ProbabilityFetcher {
  constructor(delivery, apiKey) {
    this.delivery = delivery;
    this.payload = {
      body: {
        resourceSpans: []
      },
      headers: {
        'Bugsnag-Api-Key': apiKey,
        'Content-Type': 'application/json',
        'Bugsnag-Span-Sampling': '1.0:0'
      }
    };
  }
  async getNewProbability() {
    // keep making requests until we get a new probability value from the server
    while (true) {
      const response = await this.delivery.send(this.payload);
      // in theory this should always be present, but it's possible the request
      // fails or there's a bug on the server side causing it not to be returned
      if (response.samplingProbability !== undefined) {
        return response.samplingProbability;
      }
      await this.timeBetweenRetries();
    }
  }
  timeBetweenRetries() {
    return new Promise(resolve => {
      setTimeout(resolve, RETRY_MILLISECONDS);
    });
  }
}
export { ProbabilityFetcher as default };