import { ErrorHandler, NgModule } from '@angular/core'
import BugsnagPerformance from '@bugsnag/browser-performance'
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'

Bugsnag.start({ apiKey: '41f7dcec8419a0804955e334f095d7ad' })
BugsnagPerformance.start({ apiKey: '41f7dcec8419a0804955e334f095d7ad' })

export function errorHandlerFactory() {
    return new BugsnagErrorHandler()
}

@NgModule({
    providers: [ { provide: ErrorHandler, useFactory: errorHandlerFactory } ],
})
export class BugsnagModule {}

