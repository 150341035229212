import { InMemoryPersistence, toPersistedPayload } from '@bugsnag/core-performance';
function makeBrowserPersistence(window) {
  // accessing localStorage can throw on some browsers, so we have to catch
  // these errors and provide a fallback
  try {
    if (window.localStorage) {
      return new BrowserPersistence(window.localStorage);
    }
  } catch (_a) {}
  // store items in memory if localStorage isn't available
  return new InMemoryPersistence();
}
function toString(key, value) {
  switch (key) {
    case 'bugsnag-sampling-probability':
      return JSON.stringify(value);
    case 'bugsnag-anonymous-id':
      return value;
    default:
      return key;
  }
}
class BrowserPersistence {
  constructor(localStorage) {
    this.storage = localStorage;
  }
  async load(key) {
    try {
      const raw = this.storage.getItem(key);
      if (raw) {
        return toPersistedPayload(key, raw);
      }
    } catch (_a) {}
  }
  async save(key, value) {
    try {
      this.storage.setItem(key, toString(key, value));
    } catch (_a) {}
  }
}
export { makeBrowserPersistence as default };