import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import {
    GenericScannerApiResponse,
    GetDataApiResponse,
    ManualScanApiResponse,
    ScannerInfoApiResponse,
    ScannerStatusApiResponse,
} from './types'
import { environment } from '@app-env/environment'

@Injectable({
    providedIn: 'root',
})
export class RfidScannerService {

    constructor(
        private readonly httpClient: HttpClient,
    ) {}

    public getScannerStatus(): Observable<ScannerStatusApiResponse> {
        return this.httpClient.get<ScannerStatusApiResponse>(environment.codeScanner.endpoint, {
            params: {
                action: 'getScannerStatus',
            },
        })
    }

    public getScannerInfo(): Observable<ScannerInfoApiResponse> {
        return this.httpClient.get<ScannerInfoApiResponse>(environment.codeScanner.endpoint, {
            params: {
                action: 'getScannerInfo',
            },
        })
    }

    public manualScan(timeout: number = 20): Observable<ManualScanApiResponse> {
        return this.httpClient.get<ManualScanApiResponse>(environment.codeScanner.endpoint, {
            params: {
                action: 'manualScan',
                timeout,
            },
        })
    }

    public getData(): Observable<GetDataApiResponse> {
        return this.httpClient.get<GetDataApiResponse>(environment.codeScanner.endpoint, {
            params: {
                action: 'getData',
            },
        })
    }

    public clearData(): Observable<GenericScannerApiResponse> {
        return this.httpClient.get<GenericScannerApiResponse>(environment.codeScanner.endpoint, {
            params: {
                action: 'clearData',
            },
        })
    }

}
