import { Routes } from '@angular/router'

import { ErrorPageComponent, IntroPageComponent, SuccessPageComponent, WelcomePageComponent } from '@app-pages'

export const routes: Routes = [
    {
        path: '',
        component: WelcomePageComponent,
    },
    {
        path: 'intro',
        component: IntroPageComponent,
    },
    {
        path: 'success',
        component: SuccessPageComponent,
    },
    {
        path: 'error',
        component: ErrorPageComponent,
    },
]
