import { SpanEvents } from './events.js';
import traceIdToSamplingRate from './trace-id-to-sampling-rate.js';
import { isTime, isSpanContext, isBoolean } from './validation.js';
function spanToJson(span, clock) {
  return {
    name: span.name,
    kind: span.kind,
    spanId: span.id,
    traceId: span.traceId,
    parentSpanId: span.parentSpanId,
    startTimeUnixNano: clock.toUnixTimestampNanoseconds(span.startTime),
    endTimeUnixNano: clock.toUnixTimestampNanoseconds(span.endTime),
    attributes: span.attributes.toJson(),
    events: span.events.toJson(clock)
  };
}
class SpanInternal {
  constructor(id, traceId, name, startTime, attributes, parentSpanId) {
    this.kind = 3 /* Kind.Client */; // TODO: How do we define the initial Kind?
    this.events = new SpanEvents();
    this.id = id;
    this.traceId = traceId;
    this.parentSpanId = parentSpanId;
    this.name = name;
    this.startTime = startTime;
    this.attributes = attributes;
    this.samplingRate = traceIdToSamplingRate(this.traceId);
  }
  addEvent(name, time) {
    this.events.add(name, time);
  }
  setAttribute(name, value) {
    this.attributes.set(name, value);
  }
  end(endTime, samplingProbability) {
    this.endTime = endTime;
    let _samplingProbability = samplingProbability;
    this.attributes.set('bugsnag.sampling.p', _samplingProbability.raw);
    return {
      id: this.id,
      name: this.name,
      kind: this.kind,
      traceId: this.traceId,
      startTime: this.startTime,
      attributes: this.attributes,
      events: this.events,
      samplingRate: this.samplingRate,
      endTime,
      get samplingProbability() {
        return _samplingProbability;
      },
      set samplingProbability(samplingProbability) {
        _samplingProbability = samplingProbability;
        this.attributes.set('bugsnag.sampling.p', _samplingProbability.raw);
      },
      parentSpanId: this.parentSpanId
    };
  }
  isValid() {
    return this.endTime === undefined;
  }
}
const coreSpanOptionSchema = {
  startTime: {
    message: 'should be a number or Date',
    getDefaultValue: () => undefined,
    validate: isTime
  },
  parentContext: {
    message: 'should be a SpanContext',
    getDefaultValue: () => undefined,
    validate: value => value === null || isSpanContext(value)
  },
  makeCurrentContext: {
    message: 'should be true|false',
    getDefaultValue: () => undefined,
    validate: isBoolean
  },
  isFirstClass: {
    message: 'should be true|false',
    getDefaultValue: () => undefined,
    validate: isBoolean
  }
};
export { SpanInternal, coreSpanOptionSchema, spanToJson };