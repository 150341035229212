export interface Environment {
    stage: Stage
    api: {
        schema: 'http' | 'https'
        host: string
        graphqlPath: string
    }
    facialRecognition: {
        endpoint: string
        username: string
        password: string
    }
    codeScanner: {
        endpoint: string
        username: string
        password: string
    }
}

export enum Stage {
    Test = 'test',
    Acc = 'acc',
    Prod = 'prod',
}
