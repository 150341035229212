import { provideHttpClient, withInterceptors } from '@angular/common/http'
import { ApplicationConfig } from '@angular/core'
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async'
import { provideRouter } from '@angular/router'
import { codeScannerApiAuthInterceptor } from '@app/interceptors'
import { BugsnagModule } from '@app/modules/bugsnag.module'
import BugsnagPerformance from '@bugsnag/browser-performance'
import Bugsnag from '@bugsnag/js'

import { routes } from './app.routes'
import { graphqlProvider } from './graphql.provider'

Bugsnag.start({ apiKey: '41f7dcec8419a0804955e334f095d7ad' })
BugsnagPerformance.start({ apiKey: '41f7dcec8419a0804955e334f095d7ad' })

export const appConfig: ApplicationConfig = {
    providers: [
        BugsnagModule,
        provideRouter(routes),
        provideAnimationsAsync(),
        graphqlProvider,
        provideHttpClient(
            withInterceptors([codeScannerApiAuthInterceptor]),
        ),
    ],
}
