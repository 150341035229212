import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: string; output: string; }
  /** The `DateOfBirth` scalar type represents a Date with support for zero month & days e.g: 2000-00-00. */
  DateOfBirth: { input: string; output: string; }
  DateTime: { input: string; output: string; }
  /**
   * Loose type that allows any value. Be careful when passing in large `Int` or `Float` literals,
   * as they may not be parsed correctly on the server side. Use `String` literals if you are
   * dealing with really large numbers to be on the safe side.
   */
  Mixed: { input: any; output: any; }
  /**
   * The `UUID` scalar type represents a unique identifier, often used to
   * refetch an object or as key for a cache. The ID type appears in a JSON
   * response as a String; however, it is not intended to be human-readable.
   */
  UUID: { input: string; output: string; }
  Upload: { input: File | Blob; output: File | Blob; }
};

export type AccessCode = {
  __typename?: 'AccessCode';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  type: AccessCodeTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
  visitor?: Maybe<Visitor>;
};

/** Access code type enum */
export enum AccessCodeTypeEnum {
  /** Nfc rfid */
  NfcRfid = 'NFC_RFID',
  /** Qr barcode */
  QrBarcode = 'QR_BARCODE'
}

export type AccessToken = {
  __typename?: 'AccessToken';
  contracts: Array<Contract>;
  token: Scalars['String']['output'];
};

/** Application component enum */
export enum ApplicationComponentEnum {
  /** Api */
  Api = 'API',
  /** Web application */
  WebApplication = 'WEB_APPLICATION'
}

export type ApplicationUpdate = {
  __typename?: 'ApplicationUpdate';
  component: ApplicationComponentEnum;
  forceRefresh: Scalars['Boolean']['output'];
  requiresRefresh: Scalars['Boolean']['output'];
  versionNumber: Scalars['String']['output'];
};

export type CareNote = {
  __typename?: 'CareNote';
  causeExplanation?: Maybe<Scalars['String']['output']>;
  conversationExplanation?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletableUntil?: Maybe<Scalars['DateTime']['output']>;
  deviantPlayingBehaviour: Scalars['Boolean']['output'];
  deviantPlayingTimes: Scalars['Boolean']['output'];
  enteredAt: Scalars['DateTime']['output'];
  establishment: Establishment;
  exceedsPersonalBudget: Scalars['Boolean']['output'];
  hadConversationAboutContents: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  unacceptableBehaviour: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  visitor: Visitor;
  visitorFeelsRelaxed: Scalars['Boolean']['output'];
  visitorShowsSignsOfAddiction: Scalars['Boolean']['output'];
};

export type CareNoteCountReportEntry = {
  __typename?: 'CareNoteCountReportEntry';
  careNotesPreviousPeriod: Scalars['Int']['output'];
  careNotesThisPeriod: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  ignoreReason?: Maybe<Scalars['String']['output']>;
  latestCareNote: CareNote;
  pausedUntil?: Maybe<Scalars['DateTime']['output']>;
  safetyNetReport: SafetyNetReport;
  status: SafetyNetReportEntryStatusEnum;
  updatedAt: Scalars['DateTime']['output'];
  visitor: Visitor;
  visitorNotice?: Maybe<VisitorNotice>;
};

/** A paginated list of CareNoteCountReportEntry items. */
export type CareNoteCountReportEntryPaginator = {
  __typename?: 'CareNoteCountReportEntryPaginator';
  /** A list of CareNoteCountReportEntry items. */
  data: Array<CareNoteCountReportEntry>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type Certificate = {
  __typename?: 'Certificate';
  createdAt: Scalars['DateTime']['output'];
  endsAt: Scalars['DateTime']['output'];
  establishment: Establishment;
  id: Scalars['UUID']['output'];
  key: Scalars['String']['output'];
  pem: Scalars['String']['output'];
  startsAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  validFrom: Scalars['DateTime']['output'];
  validTil: Scalars['DateTime']['output'];
};

export type ChangePasswordInput = {
  password: Scalars['String']['input'];
};

export type ChangePasswordResponse = {
  __typename?: 'ChangePasswordResponse';
  message: Scalars['String']['output'];
  status: ChangePasswordStatus;
};

export enum ChangePasswordStatus {
  /** FAILED */
  Failed = 'FAILED',
  /** PASSWORD_CHANGED */
  PasswordChanged = 'PASSWORD_CHANGED'
}

export type CheckIn = {
  __typename?: 'CheckIn';
  allowOverride?: Maybe<Scalars['Boolean']['output']>;
  contract?: Maybe<Contract>;
  createdAt: Scalars['DateTime']['output'];
  entrance: Entrance;
  flags: Array<Flag>;
  handledAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  isUnattendedCheckIn: Scalars['Boolean']['output'];
  permission: CheckInPermissionStatusEnum;
  updatedAt: Scalars['DateTime']['output'];
  visitor: Visitor;
};

export type CheckInOverride = {
  __typename?: 'CheckInOverride';
  checkIn: CheckIn;
  createdAt: Scalars['DateTime']['output'];
  explanation?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  user: User;
};

/** A paginated list of CheckIn items. */
export type CheckInPaginator = {
  __typename?: 'CheckInPaginator';
  /** A list of CheckIn items. */
  data: Array<CheckIn>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** Check in permission status enum */
export enum CheckInPermissionStatusEnum {
  /** Allowed */
  Allowed = 'ALLOWED',
  /** Denied */
  Denied = 'DENIED',
  /** Pending */
  Pending = 'PENDING'
}

/** Cm com retarget visitor type enum */
export enum CmComRetargetVisitorTypeEnum {
  /** Email */
  Email = 'EMAIL',
  /** Sms */
  Sms = 'SMS'
}

export type CodeScanInput = {
  code: Scalars['String']['input'];
  type?: InputMaybe<AccessCodeTypeEnum>;
};

export type Contract = {
  __typename?: 'Contract';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  endsAt?: Maybe<Scalars['Date']['output']>;
  establishment: Establishment;
  id: Scalars['UUID']['output'];
  role: Role;
  roles: Array<Role>;
  startsAt?: Maybe<Scalars['Date']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user: User;
};

export enum CountryCode {
  Abw = 'ABW',
  Afg = 'AFG',
  Ago = 'AGO',
  Aia = 'AIA',
  Ala = 'ALA',
  Alb = 'ALB',
  And = 'AND',
  Are = 'ARE',
  Arg = 'ARG',
  Arm = 'ARM',
  Asm = 'ASM',
  Ata = 'ATA',
  Atf = 'ATF',
  Atg = 'ATG',
  Aus = 'AUS',
  Aut = 'AUT',
  Aze = 'AZE',
  Bdi = 'BDI',
  Bel = 'BEL',
  Ben = 'BEN',
  Bes = 'BES',
  Bfa = 'BFA',
  Bgd = 'BGD',
  Bgr = 'BGR',
  Bhr = 'BHR',
  Bhs = 'BHS',
  Bih = 'BIH',
  Blm = 'BLM',
  Blr = 'BLR',
  Blz = 'BLZ',
  Bmu = 'BMU',
  Bol = 'BOL',
  Bra = 'BRA',
  Brb = 'BRB',
  Brn = 'BRN',
  Btn = 'BTN',
  Bvt = 'BVT',
  Bwa = 'BWA',
  Caf = 'CAF',
  Can = 'CAN',
  Cck = 'CCK',
  Che = 'CHE',
  Chl = 'CHL',
  Chn = 'CHN',
  Civ = 'CIV',
  Cmr = 'CMR',
  Cod = 'COD',
  Cog = 'COG',
  Cok = 'COK',
  Col = 'COL',
  Com = 'COM',
  Cpv = 'CPV',
  Cri = 'CRI',
  Cub = 'CUB',
  Cuw = 'CUW',
  Cxr = 'CXR',
  Cym = 'CYM',
  Cyp = 'CYP',
  Cze = 'CZE',
  Deu = 'DEU',
  Dji = 'DJI',
  Dma = 'DMA',
  Dnk = 'DNK',
  Dom = 'DOM',
  Dza = 'DZA',
  Ecu = 'ECU',
  Egy = 'EGY',
  Eri = 'ERI',
  Esh = 'ESH',
  Esp = 'ESP',
  Est = 'EST',
  Eth = 'ETH',
  Fin = 'FIN',
  Fji = 'FJI',
  Flk = 'FLK',
  Fra = 'FRA',
  Fro = 'FRO',
  Fsm = 'FSM',
  Gab = 'GAB',
  Gbr = 'GBR',
  Geo = 'GEO',
  Ggy = 'GGY',
  Gha = 'GHA',
  Gib = 'GIB',
  Gin = 'GIN',
  Glp = 'GLP',
  Gmb = 'GMB',
  Gnb = 'GNB',
  Gnq = 'GNQ',
  Grc = 'GRC',
  Grd = 'GRD',
  Grl = 'GRL',
  Gtm = 'GTM',
  Guf = 'GUF',
  Gum = 'GUM',
  Guy = 'GUY',
  Hkg = 'HKG',
  Hmd = 'HMD',
  Hnd = 'HND',
  Hrv = 'HRV',
  Hti = 'HTI',
  Hun = 'HUN',
  Idn = 'IDN',
  Imn = 'IMN',
  Ind = 'IND',
  Iot = 'IOT',
  Irl = 'IRL',
  Irn = 'IRN',
  Irq = 'IRQ',
  Isl = 'ISL',
  Isr = 'ISR',
  Ita = 'ITA',
  Jam = 'JAM',
  Jey = 'JEY',
  Jor = 'JOR',
  Jpn = 'JPN',
  Kaz = 'KAZ',
  Ken = 'KEN',
  Kgz = 'KGZ',
  Khm = 'KHM',
  Kir = 'KIR',
  Kna = 'KNA',
  Kor = 'KOR',
  Kwt = 'KWT',
  Lao = 'LAO',
  Lbn = 'LBN',
  Lbr = 'LBR',
  Lby = 'LBY',
  Lca = 'LCA',
  Lie = 'LIE',
  Lka = 'LKA',
  Lso = 'LSO',
  Ltu = 'LTU',
  Lux = 'LUX',
  Lva = 'LVA',
  Mac = 'MAC',
  Maf = 'MAF',
  Mar = 'MAR',
  Mco = 'MCO',
  Mda = 'MDA',
  Mdg = 'MDG',
  Mdv = 'MDV',
  Mex = 'MEX',
  Mhl = 'MHL',
  Mkd = 'MKD',
  Mli = 'MLI',
  Mlt = 'MLT',
  Mmr = 'MMR',
  Mne = 'MNE',
  Mng = 'MNG',
  Mnp = 'MNP',
  Moz = 'MOZ',
  Mrt = 'MRT',
  Msr = 'MSR',
  Mtq = 'MTQ',
  Mus = 'MUS',
  Mwi = 'MWI',
  Mys = 'MYS',
  Myt = 'MYT',
  Nam = 'NAM',
  Ncl = 'NCL',
  Ner = 'NER',
  Nfk = 'NFK',
  Nga = 'NGA',
  Nic = 'NIC',
  Niu = 'NIU',
  Nld = 'NLD',
  Nor = 'NOR',
  Npl = 'NPL',
  Nru = 'NRU',
  Nzl = 'NZL',
  Omn = 'OMN',
  Pak = 'PAK',
  Pan = 'PAN',
  Pcn = 'PCN',
  Per = 'PER',
  Phl = 'PHL',
  Plw = 'PLW',
  Png = 'PNG',
  Pol = 'POL',
  Pri = 'PRI',
  Prk = 'PRK',
  Prt = 'PRT',
  Pry = 'PRY',
  Pse = 'PSE',
  Pyf = 'PYF',
  Qat = 'QAT',
  Reu = 'REU',
  Rou = 'ROU',
  Rus = 'RUS',
  Rwa = 'RWA',
  Sau = 'SAU',
  Sdn = 'SDN',
  Sen = 'SEN',
  Sgp = 'SGP',
  Sgs = 'SGS',
  Shn = 'SHN',
  Sjm = 'SJM',
  Slb = 'SLB',
  Sle = 'SLE',
  Slv = 'SLV',
  Smr = 'SMR',
  Som = 'SOM',
  Spm = 'SPM',
  Srb = 'SRB',
  Ssd = 'SSD',
  Stp = 'STP',
  Sur = 'SUR',
  Svk = 'SVK',
  Svn = 'SVN',
  Swe = 'SWE',
  Swz = 'SWZ',
  Sxm = 'SXM',
  Syc = 'SYC',
  Syr = 'SYR',
  Tca = 'TCA',
  Tcd = 'TCD',
  Tgo = 'TGO',
  Tha = 'THA',
  Tjk = 'TJK',
  Tkl = 'TKL',
  Tkm = 'TKM',
  Tls = 'TLS',
  Ton = 'TON',
  Tto = 'TTO',
  Tun = 'TUN',
  Tur = 'TUR',
  Tuv = 'TUV',
  Twn = 'TWN',
  Tza = 'TZA',
  Uga = 'UGA',
  Ukr = 'UKR',
  Umi = 'UMI',
  Ury = 'URY',
  Usa = 'USA',
  Uzb = 'UZB',
  Vat = 'VAT',
  Vct = 'VCT',
  Ven = 'VEN',
  Vgb = 'VGB',
  Vir = 'VIR',
  Vnm = 'VNM',
  Vut = 'VUT',
  Wlf = 'WLF',
  Wsm = 'WSM',
  Yem = 'YEM',
  Zaf = 'ZAF',
  Zmb = 'ZMB',
  Zwe = 'ZWE'
}

export type CreateAccessCodeInput = {
  code: Scalars['String']['input'];
  type: AccessCodeTypeEnum;
  visitor: CreateVisitorBelongsTo;
};

export type CreateCertificateInput = {
  endsAt: Scalars['DateTime']['input'];
  establishment: CreateEstablishmentBelongsTo;
  key: Scalars['Upload']['input'];
  pem: Scalars['Upload']['input'];
  startsAt: Scalars['DateTime']['input'];
  validFrom: Scalars['DateTime']['input'];
  validTil: Scalars['DateTime']['input'];
};

export type CreateCheckInBelongsTo = {
  connect: Scalars['UUID']['input'];
};

export type CreateCheckInInput = {
  bsn?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth: Scalars['DateOfBirth']['input'];
  documentNumber?: InputMaybe<Scalars['String']['input']>;
  entrance: CreateEntranceBelongsTo;
  expiresAt?: InputMaybe<Scalars['Date']['input']>;
  firstNames?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  insertion?: InputMaybe<Scalars['String']['input']>;
  issuerCountryCode?: InputMaybe<CountryCode>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<VisitorIdentificationTypeEnum>;
  visitor?: InputMaybe<CreateVisitorBelongsTo>;
};

export type CreateEntranceBelongsTo = {
  connect: Scalars['UUID']['input'];
};

export type CreateEntranceInput = {
  establishment: CreateEstablishmentBelongsTo;
  name: Scalars['String']['input'];
};

export type CreateEstablishmentBelongsTo = {
  connect: Scalars['UUID']['input'];
};

export type CreateEstablishmentHasMany = {
  connect: Array<Scalars['UUID']['input']>;
};

export type CreateInterventionNoteOneBelongsTo = {
  connect: Scalars['UUID']['input'];
};

export type CreateInterventionNoteOneInput = {
  canBearConsequences: OptionalBooleanEnum;
  cause: InterventionNoteOneCauseEnum;
  enteredAt: Scalars['DateTime']['input'];
  establishment: CreateEstablishmentBelongsTo;
  exceptionsExplanation?: InputMaybe<Scalars['String']['input']>;
  hasBeenInformedAboutExternalHelp: Scalars['Boolean']['input'];
  hasBeenInformedAboutSelfTest: Scalars['Boolean']['input'];
  hasViewedProfile: Scalars['Boolean']['input'];
  isDependantOnGambling: OptionalBooleanEnum;
  isEncouragedToReduce: Scalars['Boolean']['input'];
  isInformedOnRisks: Scalars['Boolean']['input'];
  isNotifiedOfCause: Scalars['Boolean']['input'];
  isPresumablyTruthful: Scalars['Boolean']['input'];
  potentiallyHarmsHimselfOrOthers: OptionalBooleanEnum;
  showsSignsOfAddiction: Scalars['Boolean']['input'];
  user: CreateUserBelongsTo;
  visitor: CreateVisitorBelongsTo;
  visitorResponseLevel: InterventionNoteResponseLevelEnum;
};

export type CreateInterventionNoteTwoInput = {
  cause: InterventionNoteTwoCauseEnum;
  enteredAt: Scalars['DateTime']['input'];
  establishment: CreateEstablishmentBelongsTo;
  explanation?: InputMaybe<Scalars['String']['input']>;
  interventionMeasure: InterventionMeasuresEnum;
  interventionMeasureEndsAt: Scalars['DateTime']['input'];
  interventionMeasureTargetEstablishment: InterventionMeasureTargetEnum;
  interventionNoteOne: CreateInterventionNoteOneBelongsTo;
  numberOfVisitsPerCalendarMonth?: InputMaybe<Scalars['Int']['input']>;
  user: CreateUserBelongsTo;
  visitor: CreateVisitorBelongsTo;
  visitorResponseLevel: InterventionNoteResponseLevelEnum;
};

export type CreateRoleBelongsTo = {
  connect: Scalars['UUID']['input'];
};

export type CreateRoleHasMany = {
  connect: Array<Scalars['UUID']['input']>;
};

export type CreateScannerInput = {
  entrance: CreateEntranceBelongsTo;
  localIp?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  supplier: ScannerSupplierEnum;
  type: ScannerTypeEnum;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSecurityNoteInput = {
  enteredAt: Scalars['DateTime']['input'];
  note: Scalars['String']['input'];
  visitor: CreateVisitorBelongsTo;
};

export type CreateServiceInterviewInput = {
  cause: ServiceInterviewCauseEnum;
  causeElaboration?: InputMaybe<Scalars['String']['input']>;
  enteredAt: Scalars['DateTime']['input'];
  establishment?: InputMaybe<CreateEstablishmentBelongsTo>;
  explanation?: InputMaybe<Scalars['String']['input']>;
  hasBeenInformedAboutExternalHelp: Scalars['Boolean']['input'];
  hasBeenInformedAboutSelfTest: Scalars['Boolean']['input'];
  isInformedOnRisks: Scalars['Boolean']['input'];
  user?: InputMaybe<CreateUserBelongsTo>;
  visitor?: InputMaybe<CreateVisitorBelongsTo>;
  visitorFeelsRelaxed: Scalars['Boolean']['input'];
  visitorIsRelaxed: Scalars['Boolean']['input'];
};

export type CreateUserBelongsTo = {
  connect: Scalars['UUID']['input'];
};

export type CreateUserInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type CreateVisitorBelongsTo = {
  connect: Scalars['UUID']['input'];
};

export type CreateVisitorBlacklistInput = {
  endsAt?: InputMaybe<Scalars['Date']['input']>;
  establishments: CreateEstablishmentHasMany;
  reason: Scalars['String']['input'];
  type: VisitorBlacklistTypeEnum;
  visitor: CreateVisitorBelongsTo;
};

export type CreateVisitorIdentificationBelongsTo = {
  connect: Scalars['UUID']['input'];
};

export type CreateVisitorIdentificationInput = {
  dateOfBirth: Scalars['DateOfBirth']['input'];
  documentNumber: Scalars['String']['input'];
  establishmentId: Scalars['String']['input'];
  expiresAt: Scalars['Date']['input'];
  firstName: Scalars['String']['input'];
  hasBsn: Scalars['Boolean']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  insertion?: InputMaybe<Scalars['String']['input']>;
  issuerCountryCode: CountryCode;
  lastName: Scalars['String']['input'];
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  type: VisitorIdentificationTypeEnum;
  visitorId: Scalars['String']['input'];
};

export type CreateVisitorNoticeInput = {
  explanation: Scalars['String']['input'];
  safetyNetReportEntry?: InputMaybe<SafetyNetReportEntryInput>;
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
  taskType: VisitorTaskTypeEnum;
  visitorId: Scalars['UUID']['input'];
};

export type CreateVisitorThrottleInput = {
  endsAt: Scalars['DateTime']['input'];
  establishment: CreateEstablishmentBelongsTo;
  startsAt: Scalars['DateTime']['input'];
  visitor: CreateVisitorBelongsTo;
  visitsPerMonth: Scalars['Int']['input'];
};

export type CruksCode = {
  __typename?: 'CruksCode';
  checkIns: Array<CheckIn>;
  createdAt: Scalars['DateTime']['output'];
  establishment: Establishment;
  id: Scalars['UUID']['output'];
  isHit: Scalars['Boolean']['output'];
  isPotentiallyOutdated?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  visitor: Visitor;
  visitorIdentification?: Maybe<VisitorIdentification>;
};

/** A paginated list of CruksCode items. */
export type CruksCodePaginator = {
  __typename?: 'CruksCodePaginator';
  /** A list of CruksCode items. */
  data: Array<CruksCode>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type DateTimeRange = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Entrance = {
  __typename?: 'Entrance';
  establishment: Establishment;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  scanners: Array<Scanner>;
};

export type Establishment = {
  __typename?: 'Establishment';
  checkIns: Array<CheckIn>;
  createdAt: Scalars['DateTime']['output'];
  entrances: Array<Entrance>;
  id: Scalars['UUID']['output'];
  ipAddress: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  visitorsInPreferredList: Array<Maybe<Visitor>>;
};

export type ExternalSignalNote = {
  __typename?: 'ExternalSignalNote';
  deletableUntil?: Maybe<Scalars['DateTime']['output']>;
  enteredAt: Scalars['DateTime']['output'];
  establishment: Establishment;
  explanation: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  reporterEmail: Scalars['String']['output'];
  reporterName: Scalars['String']['output'];
  reporterPhone: Scalars['String']['output'];
  reporterRelation: Scalars['String']['output'];
  user: User;
  visitor: Visitor;
};

export type ExternalSignalNoteInput = {
  enteredAt: Scalars['DateTime']['input'];
  establishment: CreateEstablishmentBelongsTo;
  explanation: Scalars['String']['input'];
  reporterEmail: Scalars['String']['input'];
  reporterName: Scalars['String']['input'];
  reporterPhone: Scalars['String']['input'];
  reporterRelation: Scalars['String']['input'];
  user: CreateUserBelongsTo;
  visitor: CreateVisitorBelongsTo;
};

/** A paginated list of ExternalSignalNote items. */
export type ExternalSignalNotePaginator = {
  __typename?: 'ExternalSignalNotePaginator';
  /** A list of ExternalSignalNote items. */
  data: Array<ExternalSignalNote>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type FaceMatchResultDataInput = {
  className: Scalars['String']['input'];
  expectedFAR: Scalars['Float']['input'];
  expectedFRR: Scalars['Float']['input'];
  matchResult: FaceMatchResultInput;
  score: Scalars['Float']['input'];
};

export type FaceMatchResultInput = {
  asInt: Scalars['Int']['input'];
  asString: Scalars['String']['input'];
  className: Scalars['String']['input'];
};

export type FakePassport = {
  __typename?: 'FakePassport';
  bsn?: Maybe<Scalars['String']['output']>;
  dateOfBirth?: Maybe<Scalars['DateOfBirth']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expiresAt: Scalars['Date']['output'];
  firstNames?: Maybe<Scalars['String']['output']>;
  insertion?: Maybe<Scalars['String']['output']>;
  issuerCountryCode?: Maybe<CountryCode>;
  lastName?: Maybe<Scalars['String']['output']>;
  placeOfBirth?: Maybe<Scalars['String']['output']>;
  type: VisitorIdentificationTypeEnum;
};

export type FinishUnattendedCheckInInput = {
  faceMatchResultData: FaceMatchResultDataInput;
  id: Scalars['ID']['input'];
};

export type Flag = {
  __typename?: 'Flag';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  type: FlagTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
};

/** Flag type enum */
export enum FlagTypeEnum {
  /** Blocked */
  Blocked = 'BLOCKED',
  /** Info */
  Info = 'INFO',
  /** Okay */
  Okay = 'OKAY',
  /** Warning */
  Warning = 'WARNING'
}

export type ForgotPasswordInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  reset_password_url?: InputMaybe<ResetPasswordUrlInput>;
};

export type ForgotPasswordResponse = {
  __typename?: 'ForgotPasswordResponse';
  message?: Maybe<Scalars['String']['output']>;
  status: ForgotPasswordStatus;
};

export enum ForgotPasswordStatus {
  /** EMAIL_SENT */
  EmailSent = 'EMAIL_SENT'
}

export type FrequentVisitorsReportEntry = {
  __typename?: 'FrequentVisitorsReportEntry';
  averageMonthlyVisits: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  ignoreReason?: Maybe<Scalars['String']['output']>;
  pausedUntil?: Maybe<Scalars['DateTime']['output']>;
  safetyNetReport: SafetyNetReport;
  status: SafetyNetReportEntryStatusEnum;
  updatedAt: Scalars['DateTime']['output'];
  visitor: Visitor;
  visitorNotice?: Maybe<VisitorNotice>;
  visits: Scalars['Int']['output'];
};

/** A paginated list of FrequentVisitorsReportEntry items. */
export type FrequentVisitorsReportEntryPaginator = {
  __typename?: 'FrequentVisitorsReportEntryPaginator';
  /** A list of FrequentVisitorsReportEntry items. */
  data: Array<FrequentVisitorsReportEntry>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** Identity scan status */
export enum IdentityScanStatus {
  /** Failed */
  Failed = 'FAILED',
  /** Success */
  Success = 'SUCCESS'
}

export type IgnoreSafetyNetReportEntryInput = {
  reason: Scalars['String']['input'];
  safetyNetReportEntry: SafetyNetReportEntryInput;
};

export type IncreasingVisitFrequencyReportEntry = {
  __typename?: 'IncreasingVisitFrequencyReportEntry';
  createdAt: Scalars['DateTime']['output'];
  establishmentAverageFrequency: Scalars['Float']['output'];
  frequencyIncrease: Scalars['Float']['output'];
  id: Scalars['UUID']['output'];
  ignoreReason?: Maybe<Scalars['String']['output']>;
  pausedUntil?: Maybe<Scalars['DateTime']['output']>;
  safetyNetReport: SafetyNetReport;
  status: SafetyNetReportEntryStatusEnum;
  updatedAt: Scalars['DateTime']['output'];
  visitor: Visitor;
  visitorAverageFrequency: Scalars['Float']['output'];
  visitorNotice?: Maybe<VisitorNotice>;
  visits: Scalars['Int']['output'];
};

/** A paginated list of IncreasingVisitFrequencyReportEntry items. */
export type IncreasingVisitFrequencyReportEntryPaginator = {
  __typename?: 'IncreasingVisitFrequencyReportEntryPaginator';
  /** A list of IncreasingVisitFrequencyReportEntry items. */
  data: Array<IncreasingVisitFrequencyReportEntry>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type InitiateUnattendedEntryOffboardingInput = {
  initiator: OffboardingInitiatorEnum;
  reason?: InputMaybe<Scalars['String']['input']>;
  visitorId: Scalars['UUID']['input'];
};

export type InitiateUnattendedEntryOnboardingInput = {
  phoneNumber: Scalars['String']['input'];
  visitorId: Scalars['UUID']['input'];
};

export type InternalSignalNote = {
  __typename?: 'InternalSignalNote';
  deletableUntil?: Maybe<Scalars['DateTime']['output']>;
  deviatingPlayingBehaviour: Scalars['Boolean']['output'];
  deviatingPlayingTimes: Scalars['Boolean']['output'];
  deviatingStakingBehaviour: Scalars['Boolean']['output'];
  enteredAt: Scalars['DateTime']['output'];
  establishment: Establishment;
  explanation?: Maybe<Scalars['String']['output']>;
  highOrIncreasingPlayFrequency: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  isNervous: Scalars['Boolean']['output'];
  longOrIncreasedPlayDuration: Scalars['Boolean']['output'];
  other?: Maybe<Scalars['String']['output']>;
  sociallyInappropriateBehaviourTowardsOthers: Scalars['Boolean']['output'];
  suspicionOfAddiction: Scalars['Boolean']['output'];
  unacceptableBehaviour: Scalars['Boolean']['output'];
  user: User;
  visitor: Visitor;
};

export type InternalSignalNoteInput = {
  deviatingPlayingBehaviour: Scalars['Boolean']['input'];
  deviatingPlayingTimes: Scalars['Boolean']['input'];
  deviatingStakingBehaviour: Scalars['Boolean']['input'];
  enteredAt: Scalars['DateTime']['input'];
  establishment: CreateEstablishmentBelongsTo;
  explanation?: InputMaybe<Scalars['String']['input']>;
  highOrIncreasingPlayFrequency: Scalars['Boolean']['input'];
  isNervous: Scalars['Boolean']['input'];
  longOrIncreasedPlayDuration: Scalars['Boolean']['input'];
  other?: InputMaybe<Scalars['String']['input']>;
  sociallyInappropriateBehaviourTowardsOthers: Scalars['Boolean']['input'];
  suspicionOfAddiction: Scalars['Boolean']['input'];
  unacceptableBehaviour: Scalars['Boolean']['input'];
  user: CreateUserBelongsTo;
  visitor: CreateVisitorBelongsTo;
};

/** A paginated list of InternalSignalNote items. */
export type InternalSignalNotePaginator = {
  __typename?: 'InternalSignalNotePaginator';
  /** A list of InternalSignalNote items. */
  data: Array<InternalSignalNote>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** Intervention measure target enum */
export enum InterventionMeasureTargetEnum {
  /** All */
  All = 'ALL',
  /** Current */
  Current = 'CURRENT'
}

/** Intervention measures enum */
export enum InterventionMeasuresEnum {
  /** Cruks registration advised */
  CruksRegistrationAdvised = 'CRUKS_REGISTRATION_ADVISED',
  /** Cruks registration nominated */
  CruksRegistrationNominated = 'CRUKS_REGISTRATION_NOMINATED',
  /** Frequency limitation */
  FrequencyLimitation = 'FREQUENCY_LIMITATION',
  /** Involuntary access refusal */
  InvoluntaryAccessRefusal = 'INVOLUNTARY_ACCESS_REFUSAL',
  /** Voluntary access refusal */
  VoluntaryAccessRefusal = 'VOLUNTARY_ACCESS_REFUSAL'
}

export type InterventionNoteOne = {
  __typename?: 'InterventionNoteOne';
  canBearConsequences: OptionalBooleanEnum;
  cause?: Maybe<InterventionNoteOneCauseEnum>;
  createdAt: Scalars['DateTime']['output'];
  deletableUntil?: Maybe<Scalars['DateTime']['output']>;
  enteredAt: Scalars['DateTime']['output'];
  establishment: Establishment;
  exceptionsExplanation?: Maybe<Scalars['String']['output']>;
  hasBeenInformedAboutExternalHelp?: Maybe<Scalars['Boolean']['output']>;
  hasBeenInformedAboutSelfTest?: Maybe<Scalars['Boolean']['output']>;
  hasViewedProfile: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  interventionNoteTwos: Array<InterventionNoteTwo>;
  isDependantOnGambling?: Maybe<OptionalBooleanEnum>;
  isEncouragedToReduce?: Maybe<Scalars['Boolean']['output']>;
  isInformedOnRisks?: Maybe<Scalars['Boolean']['output']>;
  isNotifiedOfCause: Scalars['Boolean']['output'];
  isPresumablyTruthful: Scalars['Boolean']['output'];
  potentiallyHarmsHimselfOrOthers?: Maybe<OptionalBooleanEnum>;
  showsSignsOfAddiction: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  visitor: Visitor;
  visitorResponseLevel?: Maybe<InterventionNoteResponseLevelEnum>;
};

/** Intervention note one cause enum */
export enum InterventionNoteOneCauseEnum {
  /** Internal signal note */
  InternalSignalNote = 'INTERNAL_SIGNAL_NOTE',
  /** Safety net */
  SafetyNet = 'SAFETY_NET',
  /** Third party notification */
  ThirdPartyNotification = 'THIRD_PARTY_NOTIFICATION',
  /** Visitor signal */
  VisitorSignal = 'VISITOR_SIGNAL'
}

/** Intervention note response level enum */
export enum InterventionNoteResponseLevelEnum {
  /** Denial */
  Denial = 'DENIAL',
  /** Neutral */
  Neutral = 'NEUTRAL',
  /** Receptive */
  Receptive = 'RECEPTIVE'
}

export type InterventionNoteTwo = {
  __typename?: 'InterventionNoteTwo';
  cause: InterventionNoteTwoCauseEnum;
  createdAt: Scalars['DateTime']['output'];
  deletableUntil?: Maybe<Scalars['DateTime']['output']>;
  enteredAt: Scalars['DateTime']['output'];
  establishment: Establishment;
  explanation?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  interventionMeasure: InterventionMeasuresEnum;
  interventionMeasureEndsAt: Scalars['DateTime']['output'];
  interventionMeasureTargetEstablishment?: Maybe<InterventionMeasureTargetEnum>;
  interventionNoteOne: InterventionNoteOne;
  numberOfVisitsPerCalendarMonth?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  visitor: Visitor;
  visitorResponseLevel: InterventionNoteResponseLevelEnum;
};

/** Intervention note two cause enum */
export enum InterventionNoteTwoCauseEnum {
  /** Intervention conversation */
  InterventionConversation = 'INTERVENTION_CONVERSATION'
}

/** Involuntary access refusal target establishment enum */
export enum InvoluntaryAccessRefusalTargetEstablishmentEnum {
  /** All */
  All = 'ALL',
  /** Current */
  Current = 'CURRENT'
}

export type KsaReport = {
  __typename?: 'KsaReport';
  addedToBlacklist: Scalars['Int']['output'];
  advisedForVoluntaryCruksRegistration: Scalars['Int']['output'];
  advisedToReducePlaying: Scalars['Int']['output'];
  behaviourInsightConversations: Scalars['Int']['output'];
  endsAt: Scalars['DateTime']['output'];
  establishments: Array<Establishment>;
  informationProvidedGeneric: Scalars['Int']['output'];
  informationProvidedYouth: Scalars['Int']['output'];
  involuntarilyDeniedForPeriodOfTime: Scalars['Int']['output'];
  miscellaneousInterventionMeasures: Scalars['Int']['output'];
  startsAt: Scalars['DateTime']['output'];
  voluntarilyThrottledOrDeniedForPeriodOfTime: Scalars['Int']['output'];
};

export type KsaReportFilter = {
  dateTimeRange: MandatoryDateTimeRange;
  establishments?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type LoginInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type LogoutResponse = {
  __typename?: 'LogoutResponse';
  message: Scalars['String']['output'];
  status: LogoutStatus;
};

export enum LogoutStatus {
  /** TOKEN_REVOKED */
  TokenRevoked = 'TOKEN_REVOKED'
}

export type MandatoryDateTimeRange = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
};

export type ManualCheckInInput = {
  bsn?: InputMaybe<Scalars['String']['input']>;
  entrance: CreateEntranceBelongsTo;
  visitor: CreateVisitorBelongsTo;
};

export type Marketing = {
  __typename?: 'Marketing';
  allowed: Scalars['Boolean']['output'];
};

/** Month enum */
export enum MonthEnum {
  /** April */
  April = 'APRIL',
  /** August */
  August = 'AUGUST',
  /** December */
  December = 'DECEMBER',
  /** February */
  February = 'FEBRUARY',
  /** January */
  January = 'JANUARY',
  /** July */
  July = 'JULY',
  /** June */
  June = 'JUNE',
  /** March */
  March = 'MARCH',
  /** May */
  May = 'MAY',
  /** November */
  November = 'NOVEMBER',
  /** October */
  October = 'OCTOBER',
  /** September */
  September = 'SEPTEMBER'
}

export type Mutation = {
  __typename?: 'Mutation';
  acceptCheckIn?: Maybe<CheckIn>;
  accessCodeScan: CheckIn;
  activateUser: User;
  changePassword: ChangePasswordResponse;
  changeVisitorEmail?: Maybe<VisitorEmail>;
  changeVisitorPhone?: Maybe<VisitorPhone>;
  createAccessCode: AccessCode;
  createCertificate: Certificate;
  createCheckIn: CheckIn;
  createEntrance: Entrance;
  createExternalSignalNote: ExternalSignalNote;
  createInternalSignalNote: InternalSignalNote;
  createInterventionNoteOne: InterventionNoteOne;
  createInterventionNoteTwo: InterventionNoteTwo;
  createScanner: Scanner;
  createSecurityNote: SecurityNote;
  createServiceInterview: ServiceInterview;
  createUser: User;
  createVisitorBlacklist: VisitorBlacklist;
  createVisitorIdentification?: Maybe<VisitorIdentification>;
  createVisitorNotice: VisitorNotice;
  createVisitorThrottle: VisitorThrottle;
  deactivateUser: User;
  deactivateVisitorNotices: Array<VisitorNotice>;
  deleteAccessCode?: Maybe<AccessCode>;
  deleteCareNote?: Maybe<CareNote>;
  deleteCertificate?: Maybe<Certificate>;
  deleteCruksCode?: Maybe<CruksCode>;
  deleteEntrance?: Maybe<Entrance>;
  deleteExternalSignalNote?: Maybe<ExternalSignalNote>;
  deleteInternalSignalNote?: Maybe<InternalSignalNote>;
  deleteInterventionNoteOne?: Maybe<InterventionNoteOne>;
  deleteInterventionNoteTwo?: Maybe<InterventionNoteTwo>;
  deleteScanner?: Maybe<Scanner>;
  deleteSecurityNote?: Maybe<SecurityNote>;
  deleteServiceInterview: ServiceInterview;
  deleteUser?: Maybe<User>;
  deleteVisitor?: Maybe<Visitor>;
  deleteVisitorBlacklist?: Maybe<VisitorBlacklist>;
  deleteVisitorIdentification?: Maybe<VisitorIdentification>;
  deleteVisitorThrottle?: Maybe<VisitorThrottle>;
  finishUnattendedCheckIn: CheckIn;
  finishVisitorTasks: Array<VisitorTask>;
  forgotPassword: ForgotPasswordResponse;
  ignoreSafetyNetReportEntry: SafetyNetReportEntry;
  ignoreVisitorTask: VisitorTask;
  initiateUnattendedEntryOffboarding: UnattendedEntryPermission;
  initiateUnattendedEntryOnboarding: UnattendedEntryPermission;
  login: AccessToken;
  logout: LogoutResponse;
  manualCheckIn: CheckIn;
  mergeVisitors: Visitor;
  overrideCheckIn?: Maybe<CheckInOverride>;
  pauseSafetyNetReportEntry: SafetyNetReportEntry;
  rejectCheckIn?: Maybe<CheckIn>;
  resetPassword: ResetPasswordResponse;
  retargetVisitor?: Maybe<Scalars['Boolean']['output']>;
  startUnattendedCheckIn: UnattendedCheckIn;
  updateCertificate?: Maybe<Certificate>;
  updateEntrance: Entrance;
  updatePreferredVisitorIdentification?: Maybe<VisitorIdentification>;
  updateScanner?: Maybe<Scanner>;
  updateUser: User;
  updateUserContracts: User;
  updateVisitor?: Maybe<Visitor>;
  updateVisitorIdentification?: Maybe<VisitorIdentification>;
  updateVisitorPreference?: Maybe<VisitorPreference>;
  verifySignableFormPhoneNumber: SignableForm;
  verifyVisitorEmail: VisitorEmailVerificationResponse;
};


export type MutationAcceptCheckInArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationAccessCodeScanArgs = {
  input?: InputMaybe<CodeScanInput>;
};


export type MutationActivateUserArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationChangeVisitorEmailArgs = {
  input: ChangeVisitorEmailInput;
};


export type MutationChangeVisitorPhoneArgs = {
  input: ChangeVisitorPhoneInput;
};


export type MutationCreateAccessCodeArgs = {
  input: CreateAccessCodeInput;
};


export type MutationCreateCertificateArgs = {
  input: CreateCertificateInput;
};


export type MutationCreateCheckInArgs = {
  input?: InputMaybe<CreateCheckInInput>;
};


export type MutationCreateEntranceArgs = {
  input: CreateEntranceInput;
};


export type MutationCreateExternalSignalNoteArgs = {
  input: ExternalSignalNoteInput;
};


export type MutationCreateInternalSignalNoteArgs = {
  input: InternalSignalNoteInput;
};


export type MutationCreateInterventionNoteOneArgs = {
  input: CreateInterventionNoteOneInput;
};


export type MutationCreateInterventionNoteTwoArgs = {
  input: CreateInterventionNoteTwoInput;
};


export type MutationCreateScannerArgs = {
  input: CreateScannerInput;
};


export type MutationCreateSecurityNoteArgs = {
  input: CreateSecurityNoteInput;
};


export type MutationCreateServiceInterviewArgs = {
  input: CreateServiceInterviewInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateVisitorBlacklistArgs = {
  input?: InputMaybe<CreateVisitorBlacklistInput>;
};


export type MutationCreateVisitorIdentificationArgs = {
  input: CreateVisitorIdentificationInput;
};


export type MutationCreateVisitorNoticeArgs = {
  input: CreateVisitorNoticeInput;
};


export type MutationCreateVisitorThrottleArgs = {
  input: CreateVisitorThrottleInput;
};


export type MutationDeactivateUserArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeactivateVisitorNoticesArgs = {
  ids: Array<Scalars['UUID']['input']>;
};


export type MutationDeleteAccessCodeArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteCareNoteArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteCertificateArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteCruksCodeArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteEntranceArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteExternalSignalNoteArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteInternalSignalNoteArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteInterventionNoteOneArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteInterventionNoteTwoArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteScannerArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteSecurityNoteArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteServiceInterviewArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteVisitorArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteVisitorBlacklistArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteVisitorIdentificationArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteVisitorThrottleArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationFinishUnattendedCheckInArgs = {
  input: FinishUnattendedCheckInInput;
};


export type MutationFinishVisitorTasksArgs = {
  ids: Array<Scalars['UUID']['input']>;
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationIgnoreSafetyNetReportEntryArgs = {
  input: IgnoreSafetyNetReportEntryInput;
};


export type MutationIgnoreVisitorTaskArgs = {
  id: Scalars['UUID']['input'];
  ignoreReason?: InputMaybe<Scalars['String']['input']>;
};


export type MutationInitiateUnattendedEntryOffboardingArgs = {
  input: InitiateUnattendedEntryOffboardingInput;
};


export type MutationInitiateUnattendedEntryOnboardingArgs = {
  input: InitiateUnattendedEntryOnboardingInput;
};


export type MutationLoginArgs = {
  input?: InputMaybe<LoginInput>;
};


export type MutationManualCheckInArgs = {
  input: ManualCheckInInput;
};


export type MutationMergeVisitorsArgs = {
  mainVisitorId: Scalars['UUID']['input'];
  redundantVisitorId: Scalars['UUID']['input'];
};


export type MutationOverrideCheckInArgs = {
  input: OverrideCheckInInput;
};


export type MutationPauseSafetyNetReportEntryArgs = {
  input: PauseSafetyNetReportEntryInput;
};


export type MutationRejectCheckInArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationRetargetVisitorArgs = {
  retargetType: CmComRetargetVisitorTypeEnum;
  visitorId: Scalars['UUID']['input'];
};


export type MutationStartUnattendedCheckInArgs = {
  input: StartUnattendedCheckInInput;
};


export type MutationUpdateCertificateArgs = {
  id: Scalars['UUID']['input'];
  input: UpdateCertificateInput;
};


export type MutationUpdateEntranceArgs = {
  id: Scalars['UUID']['input'];
  input: UpdateEntranceInput;
};


export type MutationUpdatePreferredVisitorIdentificationArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationUpdateScannerArgs = {
  id: Scalars['UUID']['input'];
  input?: InputMaybe<UpdateScannerInput>;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserContractsArgs = {
  id: Scalars['UUID']['input'];
  input: UpdateUserContractsInput;
};


export type MutationUpdateVisitorArgs = {
  id: Scalars['UUID']['input'];
  input: UpdateVisitorInput;
};


export type MutationUpdateVisitorIdentificationArgs = {
  id: Scalars['UUID']['input'];
  input: UpdateVisitorIdentificationInput;
};


export type MutationUpdateVisitorPreferenceArgs = {
  id: Scalars['UUID']['input'];
  input: UpdateVisitorPreferenceInput;
};


export type MutationVerifySignableFormPhoneNumberArgs = {
  input: VerifySignableFormPhoneNumberInput;
};


export type MutationVerifyVisitorEmailArgs = {
  input?: InputMaybe<VerifyVisitorEmailInput>;
};

/** Note type enum */
export enum NoteTypeEnum {
  /** Care note */
  CareNote = 'CARE_NOTE',
  /** External signal note */
  ExternalSignalNote = 'EXTERNAL_SIGNAL_NOTE',
  /** Internal signal note */
  InternalSignalNote = 'INTERNAL_SIGNAL_NOTE',
  /** Intervention note one */
  InterventionNoteOne = 'INTERVENTION_NOTE_ONE',
  /** Intervention note two */
  InterventionNoteTwo = 'INTERVENTION_NOTE_TWO',
  /** Security note */
  SecurityNote = 'SECURITY_NOTE',
  /** Service interview */
  ServiceInterview = 'SERVICE_INTERVIEW'
}

/** Offboarding initiator enum */
export enum OffboardingInitiatorEnum {
  /** Employee */
  Employee = 'EMPLOYEE',
  /** Visitor */
  Visitor = 'VISITOR'
}

/** Optional boolean enum */
export enum OptionalBooleanEnum {
  /** False */
  False = 'FALSE',
  /** True */
  True = 'TRUE',
  /** Unknown */
  Unknown = 'UNKNOWN'
}

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String']['input'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM'
}

export type OriginNote = ExternalSignalNote | InternalSignalNote;

export type OverrideCheckInInput = {
  checkIn: CreateCheckInBelongsTo;
  email?: InputMaybe<Scalars['String']['input']>;
  explanation?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int']['output'];
  /** Index of the current page. */
  currentPage: Scalars['Int']['output'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']['output']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean']['output'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']['output']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int']['output'];
  /** Number of items per page. */
  perPage: Scalars['Int']['output'];
  /** Number of total available items. */
  total: Scalars['Int']['output'];
};

export type PauseSafetyNetReportEntryInput = {
  months: Scalars['Int']['input'];
  safetyNetReportEntry: SafetyNetReportEntryInput;
};

export type Permission = {
  __typename?: 'Permission';
  id: Scalars['UUID']['output'];
  name: PermissionEnum;
};

/** Permission enum */
export enum PermissionEnum {
  /** Access code create */
  AccessCodeCreate = 'ACCESS_CODE_CREATE',
  /** Access code delete */
  AccessCodeDelete = 'ACCESS_CODE_DELETE',
  /** Access code view any */
  AccessCodeViewAny = 'ACCESS_CODE_VIEW_ANY',
  /** Care note create */
  CareNoteCreate = 'CARE_NOTE_CREATE',
  /** Care note delete */
  CareNoteDelete = 'CARE_NOTE_DELETE',
  /** Care note view */
  CareNoteView = 'CARE_NOTE_VIEW',
  /** Care note view any */
  CareNoteViewAny = 'CARE_NOTE_VIEW_ANY',
  /** Certificate create */
  CertificateCreate = 'CERTIFICATE_CREATE',
  /** Certificate delete */
  CertificateDelete = 'CERTIFICATE_DELETE',
  /** Certificate update */
  CertificateUpdate = 'CERTIFICATE_UPDATE',
  /** Certificate view */
  CertificateView = 'CERTIFICATE_VIEW',
  /** Certificate view any */
  CertificateViewAny = 'CERTIFICATE_VIEW_ANY',
  /** Check in accept */
  CheckInAccept = 'CHECK_IN_ACCEPT',
  /** Check in cancel */
  CheckInCancel = 'CHECK_IN_CANCEL',
  /** Check in override create */
  CheckInOverrideCreate = 'CHECK_IN_OVERRIDE_CREATE',
  /** Check in override view */
  CheckInOverrideView = 'CHECK_IN_OVERRIDE_VIEW',
  /** Check in override view any */
  CheckInOverrideViewAny = 'CHECK_IN_OVERRIDE_VIEW_ANY',
  /** Check in view */
  CheckInView = 'CHECK_IN_VIEW',
  /** Check in view any */
  CheckInViewAny = 'CHECK_IN_VIEW_ANY',
  /** Contract create */
  ContractCreate = 'CONTRACT_CREATE',
  /** Contract delete */
  ContractDelete = 'CONTRACT_DELETE',
  /** Contract update */
  ContractUpdate = 'CONTRACT_UPDATE',
  /** Contract view */
  ContractView = 'CONTRACT_VIEW',
  /** Contract view any */
  ContractViewAny = 'CONTRACT_VIEW_ANY',
  /** Create unattended entry offboarding */
  CreateUnattendedEntryOffboarding = 'CREATE_UNATTENDED_ENTRY_OFFBOARDING',
  /** Create unattended entry onboarding */
  CreateUnattendedEntryOnboarding = 'CREATE_UNATTENDED_ENTRY_ONBOARDING',
  /** Cruks code delete */
  CruksCodeDelete = 'CRUKS_CODE_DELETE',
  /** Cruks code view any */
  CruksCodeViewAny = 'CRUKS_CODE_VIEW_ANY',
  /** Entrance attach establishment */
  EntranceAttachEstablishment = 'ENTRANCE_ATTACH_ESTABLISHMENT',
  /** Entrance create */
  EntranceCreate = 'ENTRANCE_CREATE',
  /** Entrance delete */
  EntranceDelete = 'ENTRANCE_DELETE',
  /** Entrance detach establishment */
  EntranceDetachEstablishment = 'ENTRANCE_DETACH_ESTABLISHMENT',
  /** Entrance update */
  EntranceUpdate = 'ENTRANCE_UPDATE',
  /** Entrance view */
  EntranceView = 'ENTRANCE_VIEW',
  /** Entrance view any */
  EntranceViewAny = 'ENTRANCE_VIEW_ANY',
  /** Establishment create */
  EstablishmentCreate = 'ESTABLISHMENT_CREATE',
  /** Establishment delete */
  EstablishmentDelete = 'ESTABLISHMENT_DELETE',
  /** Establishment input */
  EstablishmentInput = 'ESTABLISHMENT_INPUT',
  /** Establishment update */
  EstablishmentUpdate = 'ESTABLISHMENT_UPDATE',
  /** Establishment view */
  EstablishmentView = 'ESTABLISHMENT_VIEW',
  /** Establishment view any */
  EstablishmentViewAny = 'ESTABLISHMENT_VIEW_ANY',
  /** External signal note create */
  ExternalSignalNoteCreate = 'EXTERNAL_SIGNAL_NOTE_CREATE',
  /** External signal note delete */
  ExternalSignalNoteDelete = 'EXTERNAL_SIGNAL_NOTE_DELETE',
  /** External signal note view */
  ExternalSignalNoteView = 'EXTERNAL_SIGNAL_NOTE_VIEW',
  /** External signal note view any */
  ExternalSignalNoteViewAny = 'EXTERNAL_SIGNAL_NOTE_VIEW_ANY',
  /** Flag view */
  FlagView = 'FLAG_VIEW',
  /** Flag view any */
  FlagViewAny = 'FLAG_VIEW_ANY',
  /** Internal signal note create */
  InternalSignalNoteCreate = 'INTERNAL_SIGNAL_NOTE_CREATE',
  /** Internal signal note delete */
  InternalSignalNoteDelete = 'INTERNAL_SIGNAL_NOTE_DELETE',
  /** Internal signal note view */
  InternalSignalNoteView = 'INTERNAL_SIGNAL_NOTE_VIEW',
  /** Internal signal note view any */
  InternalSignalNoteViewAny = 'INTERNAL_SIGNAL_NOTE_VIEW_ANY',
  /** Intervention note one create */
  InterventionNoteOneCreate = 'INTERVENTION_NOTE_ONE_CREATE',
  /** Intervention note one delete */
  InterventionNoteOneDelete = 'INTERVENTION_NOTE_ONE_DELETE',
  /** Intervention note one view */
  InterventionNoteOneView = 'INTERVENTION_NOTE_ONE_VIEW',
  /** Intervention note one view any */
  InterventionNoteOneViewAny = 'INTERVENTION_NOTE_ONE_VIEW_ANY',
  /** Intervention note two create */
  InterventionNoteTwoCreate = 'INTERVENTION_NOTE_TWO_CREATE',
  /** Intervention note two delete */
  InterventionNoteTwoDelete = 'INTERVENTION_NOTE_TWO_DELETE',
  /** Intervention note two view */
  InterventionNoteTwoView = 'INTERVENTION_NOTE_TWO_VIEW',
  /** Intervention note two view any */
  InterventionNoteTwoViewAny = 'INTERVENTION_NOTE_TWO_VIEW_ANY',
  /** Role view */
  RoleView = 'ROLE_VIEW',
  /** Safety net report entry update */
  SafetyNetReportEntryUpdate = 'SAFETY_NET_REPORT_ENTRY_UPDATE',
  /** Safety net report entry view */
  SafetyNetReportEntryView = 'SAFETY_NET_REPORT_ENTRY_VIEW',
  /** Safety net report entry view any */
  SafetyNetReportEntryViewAny = 'SAFETY_NET_REPORT_ENTRY_VIEW_ANY',
  /** Safety net report view */
  SafetyNetReportView = 'SAFETY_NET_REPORT_VIEW',
  /** Safety net report view any */
  SafetyNetReportViewAny = 'SAFETY_NET_REPORT_VIEW_ANY',
  /** Scanner attach entrance */
  ScannerAttachEntrance = 'SCANNER_ATTACH_ENTRANCE',
  /** Scanner create */
  ScannerCreate = 'SCANNER_CREATE',
  /** Scanner delete */
  ScannerDelete = 'SCANNER_DELETE',
  /** Scanner detach entrance */
  ScannerDetachEntrance = 'SCANNER_DETACH_ENTRANCE',
  /** Scanner update */
  ScannerUpdate = 'SCANNER_UPDATE',
  /** Scanner view */
  ScannerView = 'SCANNER_VIEW',
  /** Scanner view any */
  ScannerViewAny = 'SCANNER_VIEW_ANY',
  /** Security note create */
  SecurityNoteCreate = 'SECURITY_NOTE_CREATE',
  /** Security note delete */
  SecurityNoteDelete = 'SECURITY_NOTE_DELETE',
  /** Security note view */
  SecurityNoteView = 'SECURITY_NOTE_VIEW',
  /** Security note view any */
  SecurityNoteViewAny = 'SECURITY_NOTE_VIEW_ANY',
  /** Service interview create */
  ServiceInterviewCreate = 'SERVICE_INTERVIEW_CREATE',
  /** Service interview delete */
  ServiceInterviewDelete = 'SERVICE_INTERVIEW_DELETE',
  /** Service interview view */
  ServiceInterviewView = 'SERVICE_INTERVIEW_VIEW',
  /** Service interview view any */
  ServiceInterviewViewAny = 'SERVICE_INTERVIEW_VIEW_ANY',
  /** User create */
  UserCreate = 'USER_CREATE',
  /** User delete */
  UserDelete = 'USER_DELETE',
  /** User input */
  UserInput = 'USER_INPUT',
  /** User update */
  UserUpdate = 'USER_UPDATE',
  /** User view */
  UserView = 'USER_VIEW',
  /** User view any */
  UserViewAny = 'USER_VIEW_ANY',
  /** Verify signable form phone number */
  VerifySignableFormPhoneNumber = 'VERIFY_SIGNABLE_FORM_PHONE_NUMBER',
  /** Visitor add to queue */
  VisitorAddToQueue = 'VISITOR_ADD_TO_QUEUE',
  /** Visitor blacklist create */
  VisitorBlacklistCreate = 'VISITOR_BLACKLIST_CREATE',
  /** Visitor blacklist delete */
  VisitorBlacklistDelete = 'VISITOR_BLACKLIST_DELETE',
  /** Visitor blacklist view */
  VisitorBlacklistView = 'VISITOR_BLACKLIST_VIEW',
  /** Visitor blacklist view any */
  VisitorBlacklistViewAny = 'VISITOR_BLACKLIST_VIEW_ANY',
  /** Visitor delete */
  VisitorDelete = 'VISITOR_DELETE',
  /** Visitor email create */
  VisitorEmailCreate = 'VISITOR_EMAIL_CREATE',
  /** Visitor email delete */
  VisitorEmailDelete = 'VISITOR_EMAIL_DELETE',
  /** Visitor email view */
  VisitorEmailView = 'VISITOR_EMAIL_VIEW',
  /** Visitor email view any */
  VisitorEmailViewAny = 'VISITOR_EMAIL_VIEW_ANY',
  /** Visitor identification create */
  VisitorIdentificationCreate = 'VISITOR_IDENTIFICATION_CREATE',
  /** Visitor identification delete */
  VisitorIdentificationDelete = 'VISITOR_IDENTIFICATION_DELETE',
  /** Visitor identification update */
  VisitorIdentificationUpdate = 'VISITOR_IDENTIFICATION_UPDATE',
  /** Visitor identification view */
  VisitorIdentificationView = 'VISITOR_IDENTIFICATION_VIEW',
  /** Visitor identification view any */
  VisitorIdentificationViewAny = 'VISITOR_IDENTIFICATION_VIEW_ANY',
  /** Visitor input */
  VisitorInput = 'VISITOR_INPUT',
  /** Visitor log view */
  VisitorLogView = 'VISITOR_LOG_VIEW',
  /** Visitor log view any */
  VisitorLogViewAny = 'VISITOR_LOG_VIEW_ANY',
  /** Visitor merge */
  VisitorMerge = 'VISITOR_MERGE',
  /** Visitor notice create */
  VisitorNoticeCreate = 'VISITOR_NOTICE_CREATE',
  /** Visitor notice update */
  VisitorNoticeUpdate = 'VISITOR_NOTICE_UPDATE',
  /** Visitor notice view */
  VisitorNoticeView = 'VISITOR_NOTICE_VIEW',
  /** Visitor notice view any */
  VisitorNoticeViewAny = 'VISITOR_NOTICE_VIEW_ANY',
  /** Visitor phone create */
  VisitorPhoneCreate = 'VISITOR_PHONE_CREATE',
  /** Visitor phone delete */
  VisitorPhoneDelete = 'VISITOR_PHONE_DELETE',
  /** Visitor phone update */
  VisitorPhoneUpdate = 'VISITOR_PHONE_UPDATE',
  /** Visitor phone view */
  VisitorPhoneView = 'VISITOR_PHONE_VIEW',
  /** Visitor phone view any */
  VisitorPhoneViewAny = 'VISITOR_PHONE_VIEW_ANY',
  /** Visitor preference update */
  VisitorPreferenceUpdate = 'VISITOR_PREFERENCE_UPDATE',
  /** Visitor preference view */
  VisitorPreferenceView = 'VISITOR_PREFERENCE_VIEW',
  /** Visitor preference view any */
  VisitorPreferenceViewAny = 'VISITOR_PREFERENCE_VIEW_ANY',
  /** Visitor task update */
  VisitorTaskUpdate = 'VISITOR_TASK_UPDATE',
  /** Visitor task view */
  VisitorTaskView = 'VISITOR_TASK_VIEW',
  /** Visitor task view any */
  VisitorTaskViewAny = 'VISITOR_TASK_VIEW_ANY',
  /** Visitor throttle create */
  VisitorThrottleCreate = 'VISITOR_THROTTLE_CREATE',
  /** Visitor throttle delete */
  VisitorThrottleDelete = 'VISITOR_THROTTLE_DELETE',
  /** Visitor throttle view */
  VisitorThrottleView = 'VISITOR_THROTTLE_VIEW',
  /** Visitor throttle view any */
  VisitorThrottleViewAny = 'VISITOR_THROTTLE_VIEW_ANY',
  /** Visitor update */
  VisitorUpdate = 'VISITOR_UPDATE',
  /** Visitor view */
  VisitorView = 'VISITOR_VIEW',
  /** Visitor view any */
  VisitorViewAny = 'VISITOR_VIEW_ANY'
}

export type Query = {
  __typename?: 'Query';
  accessCode?: Maybe<AccessCode>;
  careNote: CareNote;
  careNotes: Array<CareNote>;
  certificate: Certificate;
  certificates: Array<Certificate>;
  checkIn?: Maybe<CheckIn>;
  checkInQueue: Array<CheckIn>;
  checkIns: Array<CheckIn>;
  contract: Contract;
  contracts: Array<Contract>;
  cruksCodes: CruksCodePaginator;
  entrance: Entrance;
  entrances: Array<Entrance>;
  establishment?: Maybe<Establishment>;
  establishments: Array<Establishment>;
  externalSignalNote: ExternalSignalNote;
  externalSignalNotes: ExternalSignalNotePaginator;
  fakePassports: Array<FakePassport>;
  flags: Array<Flag>;
  internalSignalNote: InternalSignalNote;
  internalSignalNotes: InternalSignalNotePaginator;
  interventionNoteOne: InterventionNoteOne;
  interventionNoteOnes: Array<InterventionNoteOne>;
  interventionNoteTwo: InterventionNoteTwo;
  interventionNoteTwos: Array<InterventionNoteTwo>;
  ksaReport: KsaReport;
  marketing?: Maybe<Marketing>;
  me?: Maybe<User>;
  permission?: Maybe<Permission>;
  permissions: Array<Permission>;
  role?: Maybe<Role>;
  roles: Array<Role>;
  safetyNetReport?: Maybe<SafetyNetReport>;
  safetyNetReportPeriods: Array<Scalars['String']['output']>;
  safetyNetReports: SafetyNetReportPaginator;
  scanner?: Maybe<Scanner>;
  scanners: Array<Scanner>;
  securityNote: SecurityNote;
  securityNotes: Array<SecurityNote>;
  serviceInterview: ServiceInterview;
  serviceInterviews: Array<ServiceInterview>;
  signableForm: SignableForm;
  user?: Maybe<User>;
  users: UserPaginator;
  visitor?: Maybe<Visitor>;
  visitorBlackListReport: Array<VisitorBlacklist>;
  visitorBlacklist?: Maybe<VisitorBlacklist>;
  visitorBlacklists: Array<VisitorBlacklist>;
  visitorLogs: VisitorLogPaginator;
  visitorNotices: VisitorNoticePaginator;
  visitorTask: VisitorTask;
  visitorTasks: VisitorTaskPaginator;
  visitorThrottle: VisitorThrottle;
  visitorThrottles: Array<VisitorThrottle>;
  visitors: VisitorPaginator;
};


export type QueryAccessCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryCareNoteArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryCertificateArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryCheckInArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryCheckInQueueArgs = {
  entranceId: Scalars['UUID']['input'];
};


export type QueryContractArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryCruksCodesArgs = {
  first?: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryEntranceArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryEstablishmentArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryExternalSignalNoteArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryExternalSignalNotesArgs = {
  first?: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInternalSignalNoteArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryInternalSignalNotesArgs = {
  first?: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInterventionNoteOneArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryInterventionNoteTwoArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryKsaReportArgs = {
  input: KsaReportFilter;
};


export type QueryMarketingArgs = {
  code: Scalars['String']['input'];
};


export type QueryPermissionArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryRoleArgs = {
  id: Scalars['UUID']['input'];
};


export type QuerySafetyNetReportArgs = {
  id: Scalars['UUID']['input'];
};


export type QuerySafetyNetReportPeriodsArgs = {
  input?: InputMaybe<SafetyNetReportPeriodsFilter>;
};


export type QuerySafetyNetReportsArgs = {
  first?: Scalars['Int']['input'];
  input?: InputMaybe<SafetyNetReportFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryScannerArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryScannersArgs = {
  hasEntrance?: InputMaybe<QueryScannersHasEntranceWhereHasConditions>;
};


export type QuerySecurityNoteArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryServiceInterviewArgs = {
  id: Scalars['UUID']['input'];
};


export type QuerySignableFormArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryUsersArgs = {
  first?: Scalars['Int']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<Array<QueryUsersOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryVisitorArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryVisitorBlackListReportArgs = {
  input: VisitorBlackListReportFilter;
};


export type QueryVisitorBlacklistArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryVisitorLogsArgs = {
  first?: Scalars['Int']['input'];
  input?: InputMaybe<VisitorLogsInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryVisitorNoticesArgs = {
  first?: Scalars['Int']['input'];
  input?: InputMaybe<VisitorNoticesInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryVisitorTaskArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryVisitorTasksArgs = {
  first?: Scalars['Int']['input'];
  input?: InputMaybe<VisitorTasksInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryVisitorThrottleArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryVisitorThrottlesArgs = {
  input?: InputMaybe<VisitorThrottlesInput>;
};


export type QueryVisitorsArgs = {
  dateTimeRange?: InputMaybe<DateTimeRange>;
  first?: Scalars['Int']['input'];
  forEstablishment?: InputMaybe<Scalars['UUID']['input']>;
  orderBy?: InputMaybe<Array<QueryVisitorsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Allowed column names for Query.scanners.hasEntrance. */
export enum QueryScannersHasEntranceColumn {
  Id = 'ID'
}

/** Dynamic WHERE conditions for the `hasEntrance` argument of the query `scanners`. */
export type QueryScannersHasEntranceWhereHasConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QueryScannersHasEntranceWhereHasConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QueryScannersHasEntranceWhereHasConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QueryScannersHasEntranceWhereHasConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<QueryScannersHasEntranceColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']['input']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `hasEntrance` argument of the query `scanners`. */
export type QueryScannersHasEntranceWhereHasConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']['input']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QueryScannersHasEntranceWhereHasConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String']['input'];
};

/** Order by clause for Query.users.orderBy. */
export type QueryUsersOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: UserOrderByEnum;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type QueryVisitorsOrderByOrderByClause = {
  column: VisitorOrderByEnum;
  order: SortOrder;
};

export type ResetPasswordInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type ResetPasswordResponse = {
  __typename?: 'ResetPasswordResponse';
  message?: Maybe<Scalars['String']['output']>;
  status: ResetPasswordStatus;
};

export enum ResetPasswordStatus {
  /** PASSWORD_RESET */
  PasswordReset = 'PASSWORD_RESET'
}

/**
 * The url used to reset the password.
 * Use the `__EMAIL__` and `__TOKEN__` placeholders to inject the reset password email and token.
 *
 * e.g; `https://my-front-end.com?reset-password?email=__EMAIL__&token=__TOKEN__`
 */
export type ResetPasswordUrlInput = {
  url: Scalars['String']['input'];
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['UUID']['output'];
  name: RoleEnum;
  permissions: Array<Permission>;
};

/** Role enum */
export enum RoleEnum {
  /** Admin */
  Admin = 'ADMIN',
  /** Employee */
  Employee = 'EMPLOYEE',
  /** Engineer */
  Engineer = 'ENGINEER',
  /** Floor manager */
  FloorManager = 'FLOOR_MANAGER',
  /** Manager */
  Manager = 'MANAGER',
  /** Marketer */
  Marketer = 'MARKETER',
  /** Operations manager */
  OperationsManager = 'OPERATIONS_MANAGER',
  /** Responsible gaming officer */
  ResponsibleGamingOfficer = 'RESPONSIBLE_GAMING_OFFICER',
  /** Supervisor */
  Supervisor = 'SUPERVISOR'
}

/** The available SQL operators that are used to filter query results. */
export enum SqlOperator {
  /** Whether a value is within a range of values (`BETWEEN`) */
  Between = 'BETWEEN',
  /** Equal operator (`=`) */
  Eq = 'EQ',
  /** Greater than operator (`>`) */
  Gt = 'GT',
  /** Greater than or equal operator (`>=`) */
  Gte = 'GTE',
  /** Whether a value is within a set of values (`IN`) */
  In = 'IN',
  /** Whether a value is not null (`IS NOT NULL`) */
  IsNotNull = 'IS_NOT_NULL',
  /** Whether a value is null (`IS NULL`) */
  IsNull = 'IS_NULL',
  /** Simple pattern matching (`LIKE`) */
  Like = 'LIKE',
  /** Less than operator (`<`) */
  Lt = 'LT',
  /** Less than or equal operator (`<=`) */
  Lte = 'LTE',
  /** Not equal operator (`!=`) */
  Neq = 'NEQ',
  /** Whether a value is not within a range of values (`NOT BETWEEN`) */
  NotBetween = 'NOT_BETWEEN',
  /** Whether a value is not within a set of values (`NOT IN`) */
  NotIn = 'NOT_IN',
  /** Negation of simple pattern matching (`NOT LIKE`) */
  NotLike = 'NOT_LIKE'
}

export type SafetyNetReport = {
  __typename?: 'SafetyNetReport';
  careNoteCountReportEntries: CareNoteCountReportEntryPaginator;
  createdAt: Scalars['DateTime']['output'];
  establishment?: Maybe<Establishment>;
  frequentVisitorsReportEntries: FrequentVisitorsReportEntryPaginator;
  id: Scalars['UUID']['output'];
  increasingVisitFrequencyReportEntries: IncreasingVisitFrequencyReportEntryPaginator;
  period: Scalars['String']['output'];
  type: SafetyNetReportTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
};


export type SafetyNetReportCareNoteCountReportEntriesArgs = {
  first?: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<SafetyNetReportEntryStatusEnum>;
};


export type SafetyNetReportFrequentVisitorsReportEntriesArgs = {
  first?: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<SafetyNetReportEntryStatusEnum>;
};


export type SafetyNetReportIncreasingVisitFrequencyReportEntriesArgs = {
  first?: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<SafetyNetReportEntryStatusEnum>;
};

export type SafetyNetReportEntry = CareNoteCountReportEntry | FrequentVisitorsReportEntry | IncreasingVisitFrequencyReportEntry;

export type SafetyNetReportEntryInput = {
  id: Scalars['UUID']['input'];
  type: SafetyNetReportTypeEnum;
};

/** Safety net report entry status enum */
export enum SafetyNetReportEntryStatusEnum {
  /** Ignored */
  Ignored = 'IGNORED',
  /** Notice created */
  NoticeCreated = 'NOTICE_CREATED',
  /** Paused */
  Paused = 'PAUSED',
  /** Pending */
  Pending = 'PENDING'
}

export type SafetyNetReportFilter = {
  establishment?: InputMaybe<Scalars['UUID']['input']>;
  period?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<SafetyNetReportTypeEnum>;
};

/** A paginated list of SafetyNetReport items. */
export type SafetyNetReportPaginator = {
  __typename?: 'SafetyNetReportPaginator';
  /** A list of SafetyNetReport items. */
  data: Array<SafetyNetReport>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type SafetyNetReportPeriodsFilter = {
  establishment?: InputMaybe<Scalars['UUID']['input']>;
  type?: InputMaybe<SafetyNetReportTypeEnum>;
};

/** Safety net report type enum */
export enum SafetyNetReportTypeEnum {
  /** zorgnotitiemonitor */
  CareNoteCount = 'CARE_NOTE_COUNT',
  /** snelle stijgers */
  IncreasingVisitFrequency = 'INCREASING_VISIT_FREQUENCY',
  /** meeste bezoeken */
  MostFrequentVisitors = 'MOST_FREQUENT_VISITORS',
  /** meeste bezoeken jeugdmonitor */
  MostFrequentVisitorsYouth = 'MOST_FREQUENT_VISITORS_YOUTH'
}

/** Scan status enum */
export enum ScanStatusEnum {
  /** Awaiting flip */
  AwaitingFlip = 'AWAITING_FLIP',
  /** Scan failure */
  ScanFailure = 'SCAN_FAILURE',
  /** Scan started */
  ScanStarted = 'SCAN_STARTED',
  /** Scan success */
  ScanSuccess = 'SCAN_SUCCESS',
  /** Unknown */
  Unknown = 'UNKNOWN'
}

export type Scanner = {
  __typename?: 'Scanner';
  createdAt: Scalars['DateTime']['output'];
  entrance?: Maybe<Entrance>;
  id: Scalars['UUID']['output'];
  localIp?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  password?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ScanStatusEnum>;
  statusMessage?: Maybe<Scalars['String']['output']>;
  supplier: ScannerSupplierEnum;
  type: ScannerTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

/** Scanner supplier enum */
export enum ScannerSupplierEnum {
  /** Oribi */
  Oribi = 'ORIBI',
  /** Scan id */
  ScanId = 'SCAN_ID'
}

/** Scanner type enum */
export enum ScannerTypeEnum {
  /** Identification scanner */
  IdentificationScanner = 'IDENTIFICATION_SCANNER',
  /** Rfid scanner */
  RfidScanner = 'RFID_SCANNER'
}

export type SecurityNote = {
  __typename?: 'SecurityNote';
  createdAt: Scalars['DateTime']['output'];
  deletableUntil?: Maybe<Scalars['DateTime']['output']>;
  enteredAt: Scalars['DateTime']['output'];
  establishment: Establishment;
  id: Scalars['UUID']['output'];
  note: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  visitor: Visitor;
};

export type ServiceInterview = {
  __typename?: 'ServiceInterview';
  cause: ServiceInterviewCauseEnum;
  causeElaboration?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletableUntil?: Maybe<Scalars['DateTime']['output']>;
  enteredAt: Scalars['DateTime']['output'];
  establishment: Establishment;
  explanation?: Maybe<Scalars['String']['output']>;
  hasBeenInformedAboutExternalHelp?: Maybe<Scalars['Boolean']['output']>;
  hasBeenInformedAboutSelfTest?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  isInformedOnRisks?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  visitor: Visitor;
  visitorFeelsRelaxed: Scalars['Boolean']['output'];
  visitorIsRelaxed: Scalars['Boolean']['output'];
};

/** Service interview cause enum */
export enum ServiceInterviewCauseEnum {
  /** Notification youth monitor */
  NotificationYouthMonitor = 'NOTIFICATION_YOUTH_MONITOR',
  /** Other */
  Other = 'OTHER'
}

export type SignableForm = {
  __typename?: 'SignableForm';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  phoneNumber: Scalars['String']['output'];
  phoneNumberVerifiedAt?: Maybe<Scalars['DateTime']['output']>;
  signableFormUrl: Scalars['String']['output'];
  signedFormDownloadUrl?: Maybe<Scalars['String']['output']>;
  signedFormUrl?: Maybe<Scalars['String']['output']>;
  type: SignableFormTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
  visitor: Visitor;
};

/** Signable form type enum */
export enum SignableFormTypeEnum {
  /** Offboarding */
  Offboarding = 'OFFBOARDING',
  /** Onboarding */
  Onboarding = 'ONBOARDING'
}

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type StartUnattendedCheckInInput = {
  code: Scalars['String']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  applicationUpdated?: Maybe<ApplicationUpdate>;
  checkInCompleted?: Maybe<CheckIn>;
  checkInUpdated?: Maybe<CheckIn>;
  newCheckIn?: Maybe<CheckIn>;
  newVisitorTask?: Maybe<VisitorTask>;
  scannerStatusChanged?: Maybe<Scanner>;
  visitorTaskUpdated?: Maybe<VisitorTask>;
};


export type SubscriptionCheckInCompletedArgs = {
  entranceId: Scalars['UUID']['input'];
};


export type SubscriptionCheckInUpdatedArgs = {
  entranceId: Scalars['UUID']['input'];
};


export type SubscriptionNewCheckInArgs = {
  entranceId: Scalars['UUID']['input'];
};


export type SubscriptionScannerStatusChangedArgs = {
  scannerId: Scalars['UUID']['input'];
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type UnattendedCheckIn = {
  __typename?: 'UnattendedCheckIn';
  checkIn?: Maybe<CheckIn>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  scanner: Scanner;
  updatedAt: Scalars['DateTime']['output'];
  visitor: Visitor;
};

export type UnattendedEntryPermission = {
  __typename?: 'UnattendedEntryPermission';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  initiatedAt?: Maybe<Establishment>;
  initiator: UnattendedEntryPermissionInitiator;
  reason?: Maybe<Scalars['String']['output']>;
  signableForm?: Maybe<SignableForm>;
  status: UnattendedEntryPermissionStatusEnum;
  updatedAt: Scalars['DateTime']['output'];
  visitor: Visitor;
};

export type UnattendedEntryPermissionInitiator = User | Visitor;

/** Unattended entry permission status enum */
export enum UnattendedEntryPermissionStatusEnum {
  /** Offboarding */
  Offboarding = 'OFFBOARDING',
  /** Onboarding */
  Onboarding = 'ONBOARDING'
}

export type UpdateCertificateInput = {
  endsAt?: InputMaybe<Scalars['DateTime']['input']>;
  establishment?: InputMaybe<CreateEstablishmentBelongsTo>;
  key?: InputMaybe<Scalars['Upload']['input']>;
  pem?: InputMaybe<Scalars['Upload']['input']>;
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
  validFrom?: InputMaybe<Scalars['DateTime']['input']>;
  validTil?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateEntranceInput = {
  name: Scalars['String']['input'];
};

export type UpdateScannerInput = {
  entrance?: InputMaybe<CreateEntranceBelongsTo>;
  localIp?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  supplier?: InputMaybe<ScannerSupplierEnum>;
  type?: InputMaybe<ScannerTypeEnum>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserContractsInput = {
  contracts: Array<UserContract>;
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateVisitorIdentificationInput = {
  dateOfBirth?: InputMaybe<Scalars['DateOfBirth']['input']>;
  documentNumber?: InputMaybe<Scalars['String']['input']>;
  expiresAt?: InputMaybe<Scalars['Date']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hasBsn?: InputMaybe<Scalars['Boolean']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  insertion?: InputMaybe<Scalars['String']['input']>;
  issuerCountryCode?: InputMaybe<CountryCode>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<VisitorIdentificationTypeEnum>;
};

export type UpdateVisitorInput = {
  acceptsEmailMarketingCommunication?: InputMaybe<Scalars['Boolean']['input']>;
  acceptsSmsMarketingCommunication?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  isDeprecated?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  preferredEstablishment?: InputMaybe<CreateEstablishmentBelongsTo>;
  profile?: InputMaybe<Scalars['Upload']['input']>;
  quitClaimSigned?: InputMaybe<Scalars['Boolean']['input']>;
  rejectionVoucherReceived?: InputMaybe<Scalars['Boolean']['input']>;
  searchableNumber?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  contracts: Array<Contract>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  establishment: Establishment;
  firstName: Scalars['String']['output'];
  hasTemporaryPassword: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  isActive: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type UserContract = {
  establishmentId: Scalars['UUID']['input'];
  role: RoleEnum;
};

/** User order by enum */
export enum UserOrderByEnum {
  /** Created at */
  CreatedAt = 'CREATED_AT',
  /** First name */
  FirstName = 'FIRST_NAME',
  /** Last name */
  LastName = 'LAST_NAME'
}

/** A paginated list of User items. */
export type UserPaginator = {
  __typename?: 'UserPaginator';
  /** A list of User items. */
  data: Array<User>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type VerifySignableFormPhoneNumberInput = {
  signableFormId: Scalars['UUID']['input'];
  verificationCode: Scalars['Int']['input'];
};

export type VerifyVisitorEmailInput = {
  hash: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
};

export type Visitor = {
  __typename?: 'Visitor';
  /** @deprecated Use the emailSingleOptIn field instead */
  acceptsEmailMarketingCommunication?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use the smsSingleOptIn field instead */
  acceptsSmsMarketingCommunication?: Maybe<Scalars['Boolean']['output']>;
  accessCodes: Array<AccessCode>;
  careNotes: Array<CareNote>;
  checkInHistory: CheckInPaginator;
  createdAt: Scalars['DateTime']['output'];
  cruksCodes: Array<CruksCode>;
  dateOfBirth: Scalars['DateOfBirth']['output'];
  emailDoubleOptIn?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use the emailSingleOptInApprovalAskedAt field instead */
  emailMarketingCommunicationsApprovalAskedAt?: Maybe<Scalars['DateTime']['output']>;
  emailSingleOptIn?: Maybe<Scalars['Boolean']['output']>;
  emailSingleOptInApprovalAskedAt?: Maybe<Scalars['DateTime']['output']>;
  externalSignalNotes: Array<ExternalSignalNote>;
  firstNames?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the visitorIdentificationWithBsnAndCruksCodeForEstablishment relationship instead, for better performance */
  hasIdentificationWithBsnAndCruksCode: Scalars['Boolean']['output'];
  hasUnattendedCheckInPermission?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  internalSignalNotes: Array<InternalSignalNote>;
  interventionNoteOnes: Array<InterventionNoteOne>;
  interventionNoteTwos: Array<InterventionNoteTwo>;
  isDeprecated: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  marketingCommunicationsAllowed?: Maybe<Scalars['Boolean']['output']>;
  phoneNumber?: Maybe<VisitorPhone>;
  preferredEstablishment?: Maybe<Establishment>;
  profile?: Maybe<Scalars['String']['output']>;
  quitClaimSigned: Scalars['Boolean']['output'];
  rejectionVoucherReceivedVisitorLog?: Maybe<VisitorLog>;
  searchableNumber?: Maybe<Scalars['String']['output']>;
  securityNotes: Array<SecurityNote>;
  serviceInterviews: Array<ServiceInterview>;
  smsDoubleOptIn?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use the smsSingleOptInApprovalAskedAt field instead */
  smsMarketingCommunicationsApprovalAskedAt?: Maybe<Scalars['DateTime']['output']>;
  smsSingleOptIn?: Maybe<Scalars['Boolean']['output']>;
  smsSingleOptInApprovalAskedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  visitorBlacklist?: Maybe<VisitorBlacklist>;
  visitorBlacklists: Array<VisitorBlacklist>;
  visitorEmail?: Maybe<VisitorEmail>;
  visitorEmailHistory: Array<Maybe<VisitorEmail>>;
  visitorIdentificationWithBsnAndCruksCodeForEstablishment?: Maybe<VisitorIdentification>;
  visitorIdentifications: Array<VisitorIdentification>;
  visitorLogs: Array<VisitorLog>;
  visitorPreference: VisitorPreference;
  visitorTasks: Array<VisitorTask>;
  visitorThrottles: Array<VisitorThrottle>;
};


export type VisitorCheckInHistoryArgs = {
  first?: Scalars['Int']['input'];
  forEstablishment?: InputMaybe<Scalars['UUID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type VisitorProfileArgs = {
  variant?: InputMaybe<VisitorProfileVariant>;
};


export type VisitorVisitorLogsArgs = {
  limitToContract?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VisitorBlackListReportFilter = {
  dateTimeRange: MandatoryDateTimeRange;
  establishments?: InputMaybe<Array<Scalars['UUID']['input']>>;
  status?: InputMaybe<VisitorBlackListStatusEnum>;
  type?: InputMaybe<VisitorBlacklistTypeEnum>;
};

/** Visitor black list status enum */
export enum VisitorBlackListStatusEnum {
  /** Active */
  Active = 'ACTIVE',
  /** Inactive */
  Inactive = 'INACTIVE'
}

export type VisitorBlacklist = {
  __typename?: 'VisitorBlacklist';
  createdAt: Scalars['DateTime']['output'];
  endsAt: Scalars['DateTime']['output'];
  establishments: Array<Establishment>;
  id: Scalars['UUID']['output'];
  interventionNoteTwo?: Maybe<InterventionNoteTwo>;
  reason: Scalars['String']['output'];
  status: VisitorBlackListStatusEnum;
  type: VisitorBlacklistTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
  visitor: Visitor;
};

/** Visitor blacklist type enum */
export enum VisitorBlacklistTypeEnum {
  /** Entreeverbod opgelegd tot :date */
  Explicit = 'EXPLICIT',
  /** Onvrijwillige toegangsontzegging tot :date */
  InvoluntaryAccessRefusal = 'INVOLUNTARY_ACCESS_REFUSAL',
  /** Onvrijwillige cruks registratie noodzakelijk tot :date */
  InvoluntaryCruksRegistration = 'INVOLUNTARY_CRUKS_REGISTRATION',
  /** Vrijwillige toegangsontzegging tot :date */
  VoluntaryAccessRefusal = 'VOLUNTARY_ACCESS_REFUSAL',
  /** Geadviseerd tot cruks registratie tot :date */
  VoluntaryCruksRegistration = 'VOLUNTARY_CRUKS_REGISTRATION'
}

export type VisitorEmail = {
  __typename?: 'VisitorEmail';
  email: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  visitor: Visitor;
};

export type VisitorEmailVerificationResponse = {
  __typename?: 'VisitorEmailVerificationResponse';
  status: VisitorEmailVerificationStatus;
};

export enum VisitorEmailVerificationStatus {
  /** EMAIL_SENT */
  EmailSent = 'EMAIL_SENT',
  /** VERIFIED */
  Verified = 'VERIFIED'
}

export type VisitorIdentification = {
  __typename?: 'VisitorIdentification';
  checkIns: Array<CheckIn>;
  createdAt: Scalars['DateTime']['output'];
  cruksCodeForEstablishment?: Maybe<CruksCode>;
  cruksCodes: Array<CruksCode>;
  dateOfBirth?: Maybe<Scalars['DateOfBirth']['output']>;
  documentNumber?: Maybe<Scalars['String']['output']>;
  expiresAt?: Maybe<Scalars['Date']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  hasBsn?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use the cruksCodeForEstablishment relationship instead, for better performance */
  hasCruksCode: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  insertion?: Maybe<Scalars['String']['output']>;
  isPreferred: Scalars['Boolean']['output'];
  issuerCountryCode?: Maybe<CountryCode>;
  lastName?: Maybe<Scalars['String']['output']>;
  placeOfBirth?: Maybe<Scalars['String']['output']>;
  type: VisitorIdentificationTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
  visitorId: Scalars['String']['output'];
};


export type VisitorIdentificationImageArgs = {
  variant?: InputMaybe<VisitorIdentificationImageVariant>;
};

/** Allowed resizes for the `variant` argument on the query `image`. */
export enum VisitorIdentificationImageVariant {
  Original = 'ORIGINAL',
  Resize_50X50 = 'RESIZE_50X50',
  Resize_255X255 = 'RESIZE_255X255'
}

/** Visitor identification type enum */
export enum VisitorIdentificationTypeEnum {
  /** Drivers license */
  DriversLicense = 'DRIVERS_LICENSE',
  /** Id */
  Id = 'ID',
  /** Miscellaneous */
  Miscellaneous = 'MISCELLANEOUS',
  /** Passport */
  Passport = 'PASSPORT',
  /** Residence permit */
  ResidencePermit = 'RESIDENCE_PERMIT'
}

export type VisitorLog = {
  __typename?: 'VisitorLog';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  establishment?: Maybe<Establishment>;
  id: Scalars['UUID']['output'];
  relatable?: Maybe<VisitorLogRelatables>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  visitor: Visitor;
};

/** A paginated list of VisitorLog items. */
export type VisitorLogPaginator = {
  __typename?: 'VisitorLogPaginator';
  /** A list of VisitorLog items. */
  data: Array<VisitorLog>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type VisitorLogRelatables = CareNote | CareNoteCountReportEntry | CheckIn | CheckInOverride | ExternalSignalNote | FrequentVisitorsReportEntry | IncreasingVisitFrequencyReportEntry | InternalSignalNote | InterventionNoteOne | InterventionNoteTwo | SecurityNote | ServiceInterview | UnattendedCheckIn | VisitorBlacklist | VisitorTask;

/** Visitor log type enum */
export enum VisitorLogTypeEnum {
  /** All except check ins */
  AllExceptCheckIns = 'ALL_EXCEPT_CHECK_INS',
  /** Black lists */
  BlackLists = 'BLACK_LISTS',
  /** Check ins */
  CheckIns = 'CHECK_INS',
  /** Notes */
  Notes = 'NOTES',
  /** Tasks */
  Tasks = 'TASKS'
}

export type VisitorLogsInput = {
  subFilters?: InputMaybe<VisitorLogsInputSubFilter>;
  type?: InputMaybe<VisitorLogTypeEnum>;
  visitorId?: InputMaybe<Scalars['UUID']['input']>;
};

export type VisitorLogsInputSubFilter = {
  checkInPermissionStatuses?: InputMaybe<Array<CheckInPermissionStatusEnum>>;
  noteTypes?: InputMaybe<Array<NoteTypeEnum>>;
  taskStatuses?: InputMaybe<Array<VisitorTaskStatusEnum>>;
};

export type VisitorNotice = {
  __typename?: 'VisitorNotice';
  establishment?: Maybe<Establishment>;
  explanation: Scalars['String']['output'];
  handledAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  originNote?: Maybe<OriginNote>;
  originType?: Maybe<VisitorNoticeOriginTypeEnum>;
  safetyNetReportEntry?: Maybe<SafetyNetReportEntry>;
  startsAt: Scalars['DateTime']['output'];
  taskType: VisitorTaskTypeEnum;
  visitor: Visitor;
  visitorTasks?: Maybe<Array<VisitorTask>>;
};

/** Visitor notice origin type enum */
export enum VisitorNoticeOriginTypeEnum {
  /** Manual */
  Manual = 'MANUAL',
  /** Safety net increasing visit frequency */
  SafetyNetIncreasingVisitFrequency = 'SAFETY_NET_INCREASING_VISIT_FREQUENCY',
  /** Safety net most frequent visitors */
  SafetyNetMostFrequentVisitors = 'SAFETY_NET_MOST_FREQUENT_VISITORS',
  /** Safety net most frequent visitors youth */
  SafetyNetMostFrequentVisitorsYouth = 'SAFETY_NET_MOST_FREQUENT_VISITORS_YOUTH',
  /** Youth monitor */
  YouthMonitor = 'YOUTH_MONITOR'
}

/** A paginated list of VisitorNotice items. */
export type VisitorNoticePaginator = {
  __typename?: 'VisitorNoticePaginator';
  /** A list of VisitorNotice items. */
  data: Array<VisitorNotice>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** Visitor notice status enum */
export enum VisitorNoticeStatusEnum {
  /** Active */
  Active = 'ACTIVE',
  /** Handled */
  Handled = 'HANDLED',
  /** Planned */
  Planned = 'PLANNED'
}

export type VisitorNoticesInput = {
  establishmentId?: InputMaybe<Scalars['UUID']['input']>;
  isHandled?: InputMaybe<Scalars['Boolean']['input']>;
  origin?: InputMaybe<VisitorNoticeOriginTypeEnum>;
  startsAt?: InputMaybe<DateTimeRange>;
  status?: InputMaybe<VisitorNoticeStatusEnum>;
  visitorId?: InputMaybe<Scalars['UUID']['input']>;
};

/** Visitor order by enum */
export enum VisitorOrderByEnum {
  /** Created at */
  CreatedAt = 'CREATED_AT',
  /** First names */
  FirstNames = 'FIRST_NAMES',
  /** Last check in */
  LastCheckIn = 'LAST_CHECK_IN',
  /** Last name */
  LastName = 'LAST_NAME'
}

/** A paginated list of Visitor items. */
export type VisitorPaginator = {
  __typename?: 'VisitorPaginator';
  /** A list of Visitor items. */
  data: Array<Visitor>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type VisitorPhone = {
  __typename?: 'VisitorPhone';
  id: Scalars['UUID']['output'];
  number: Scalars['String']['output'];
  visitor: Visitor;
};

export type VisitorPreference = {
  __typename?: 'VisitorPreference';
  doesNotWantToGiveEmail: Scalars['Boolean']['output'];
  doesNotWantToGivePhoneNumber: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  visitor: Visitor;
};

/** Allowed resizes for the `variant` argument on the query `profile`. */
export enum VisitorProfileVariant {
  Original = 'ORIGINAL',
  Resize_50X50 = 'RESIZE_50X50',
  Resize_255X255 = 'RESIZE_255X255'
}

/** Visitor reaction to measures enum */
export enum VisitorReactionToMeasuresEnum {
  /** Neutral */
  Neutral = 'NEUTRAL',
  /** Receptive */
  Receptive = 'RECEPTIVE',
  /** Unperceptive */
  Unperceptive = 'UNPERCEPTIVE'
}

export type VisitorTask = {
  __typename?: 'VisitorTask';
  checkIn?: Maybe<CheckIn>;
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  ignoreReason?: Maybe<Scalars['String']['output']>;
  interventionNoteOne?: Maybe<InterventionNoteOne>;
  status: VisitorTaskStatusEnum;
  type: VisitorTaskTypeEnum;
  visitor: Visitor;
  visitorLogs?: Maybe<Array<VisitorLog>>;
  visitorNotice?: Maybe<VisitorNotice>;
};

/** A paginated list of VisitorTask items. */
export type VisitorTaskPaginator = {
  __typename?: 'VisitorTaskPaginator';
  /** A list of VisitorTask items. */
  data: Array<VisitorTask>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** Visitor task status enum */
export enum VisitorTaskStatusEnum {
  /** Done */
  Done = 'DONE',
  /** Expired */
  Expired = 'EXPIRED',
  /** Ignored */
  Ignored = 'IGNORED',
  /** Pending */
  Pending = 'PENDING'
}

/** Visitor task type enum */
export enum VisitorTaskTypeEnum {
  /** Voer een servicegesprek */
  ConductServiceInterview = 'CONDUCT_SERVICE_INTERVIEW',
  /** Maak een interventienotitie (1) */
  CreateInterventionNoteOne = 'CREATE_INTERVENTION_NOTE_ONE'
}

export type VisitorTasksInput = {
  isDone?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<VisitorTaskTypeEnum>;
  visitorId?: InputMaybe<Scalars['UUID']['input']>;
};

export type VisitorThrottle = {
  __typename?: 'VisitorThrottle';
  createdAt: Scalars['DateTime']['output'];
  endsAt: Scalars['DateTime']['output'];
  establishment: Establishment;
  id: Scalars['UUID']['output'];
  startsAt: Scalars['DateTime']['output'];
  status: VisitorThrottleStatusEnum;
  updatedAt: Scalars['DateTime']['output'];
  visitor: Visitor;
  visitsPerMonth: Scalars['Int']['output'];
};

/** Visitor throttle status enum */
export enum VisitorThrottleStatusEnum {
  /** Active */
  Active = 'ACTIVE',
  /** Inactive */
  Inactive = 'INACTIVE'
}

export type VisitorThrottlesInput = {
  status?: InputMaybe<VisitorThrottleStatusEnum>;
};

/** Voluntary access refusal target establishment enum */
export enum VoluntaryAccessRefusalTargetEstablishmentEnum {
  /** All */
  All = 'ALL',
  /** Current */
  Current = 'CURRENT'
}

/** Dynamic WHERE conditions for queries. */
export type WhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<WhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<WhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<WhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<Scalars['String']['input']>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']['input']>;
};

/** Dynamic HAS conditions for WHERE condition queries. */
export type WhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']['input']>;
  /** Additional condition logic. */
  condition?: InputMaybe<WhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String']['input'];
};

export type ChangeVisitorEmailInput = {
  email: Scalars['String']['input'];
  visitor: CreateVisitorBelongsTo;
};

export type ChangeVisitorPhoneInput = {
  number?: InputMaybe<Scalars['String']['input']>;
  visitor: CreateVisitorBelongsTo;
};

export type UpdateVisitorPreferenceInput = {
  doesNotWantToGiveEmail?: InputMaybe<Scalars['Boolean']['input']>;
  doesNotWantToGivePhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
};

export const CheckInFragmentDoc = gql`
    fragment CheckIn on CheckIn {
  id
  permission
  entrance {
    id
    name
    scanners {
      name
      id
    }
  }
}
    `;
export const VisitorFragmentDoc = gql`
    fragment Visitor on Visitor {
  profile
}
    `;
export const UnattendedCheckInFragmentDoc = gql`
    fragment UnattendedCheckIn on UnattendedCheckIn {
  createdAt
  id
  createdAt
  updatedAt
  visitor {
    visitorIdentifications {
      isPreferred
      image
    }
    ...Visitor
  }
}
    ${VisitorFragmentDoc}`;
export const StartUnattendedCheckInDocument = gql`
    mutation startUnattendedCheckIn($input: StartUnattendedCheckInInput!) {
  startUnattendedCheckIn(input: $input) {
    ...UnattendedCheckIn
  }
}
    ${UnattendedCheckInFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StartUnattendedCheckInMutationService extends Apollo.Mutation<StartUnattendedCheckInMutation, StartUnattendedCheckInMutationVariables> {
    override document = StartUnattendedCheckInDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FinishUnattendedCheckInDocument = gql`
    mutation finishUnattendedCheckIn($input: FinishUnattendedCheckInInput!) {
  finishUnattendedCheckIn(input: $input) {
    ...CheckIn
  }
}
    ${CheckInFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FinishUnattendedCheckInMutationService extends Apollo.Mutation<FinishUnattendedCheckInMutation, FinishUnattendedCheckInMutationVariables> {
    override document = FinishUnattendedCheckInDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export type CheckInFragment = { __typename?: 'CheckIn', id: string, permission: CheckInPermissionStatusEnum, entrance: { __typename?: 'Entrance', id: string, name: string, scanners: Array<{ __typename?: 'Scanner', name: string, id: string }> } };

export type StartUnattendedCheckInMutationVariables = Exact<{
  input: StartUnattendedCheckInInput;
}>;


export type StartUnattendedCheckInMutation = { __typename?: 'Mutation', startUnattendedCheckIn: { __typename?: 'UnattendedCheckIn', createdAt: string, id: string, updatedAt: string, visitor: { __typename?: 'Visitor', profile?: string | null, visitorIdentifications: Array<{ __typename?: 'VisitorIdentification', isPreferred: boolean, image?: string | null }> } } };

export type FinishUnattendedCheckInMutationVariables = Exact<{
  input: FinishUnattendedCheckInInput;
}>;


export type FinishUnattendedCheckInMutation = { __typename?: 'Mutation', finishUnattendedCheckIn: { __typename?: 'CheckIn', id: string, permission: CheckInPermissionStatusEnum, entrance: { __typename?: 'Entrance', id: string, name: string, scanners: Array<{ __typename?: 'Scanner', name: string, id: string }> } } };

export type UnattendedCheckInFragment = { __typename?: 'UnattendedCheckIn', createdAt: string, id: string, updatedAt: string, visitor: { __typename?: 'Visitor', profile?: string | null, visitorIdentifications: Array<{ __typename?: 'VisitorIdentification', isPreferred: boolean, image?: string | null }> } };

export type VisitorFragment = { __typename?: 'Visitor', profile?: string | null };
