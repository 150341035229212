import { Component } from '@angular/core'
import { RouterOutlet } from '@angular/router'

import { NotificationBarComponent } from '@app-ui/components'
import { HeadingDirective } from '@app-ui/directives'

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, NotificationBarComponent, HeadingDirective],
    templateUrl: './app.component.html',
})
export class AppComponent {
}
