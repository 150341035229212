import { booleanAttribute, Directive, HostBinding, Input } from '@angular/core'

@Directive({
    selector: '[appParagraph]',
    standalone: true,
})
export class ParagraphDirective {

    @Input({ transform: booleanAttribute })
    public isLarge: boolean = false

    @Input({ transform: booleanAttribute })
    public isFaded: boolean = false

    @Input({ transform: booleanAttribute })
    public isBold: boolean = false

    @Input({ transform: booleanAttribute })
    public isMedium: boolean = false

    @HostBinding('class') get classes(): string {
        let classes: Array<string> = []
        classes.push('dir-paragraph')
        if (this.isLarge) {
            classes.push('dir-paragraph__large')
        }
        if (this.isBold) {
            classes.push('dir-paragraph__bold')
        }
        if (this.isMedium) {
            classes.push('dir-paragraph__medium')
        }
        if (this.isFaded) {
            classes.push('dir-paragraph__faded')
        }
        return classes.join(' ')
    }

}
