import { AsyncPipe, JsonPipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { MatProgressSpinner } from '@angular/material/progress-spinner'
import { NotificationBarComponent } from '@app/domains/ui/components/notification-bar/notification-bar.component'
import { HeadingDirective } from '@app/domains/ui/directives/heading/heading.directive'
import { ParagraphDirective } from '@app/domains/ui/directives/paragraph/paragraph.directive'
import { DefaultLayoutComponent } from '@app/domains/ui/layouts/default/default.layout.component'
import { FaceMatchService } from '@app/services'

@Component({
    selector: 'app-intro',
    standalone: true,
    imports: [
        DefaultLayoutComponent,
        HeadingDirective,
        AsyncPipe,
        JsonPipe,
        MatProgressSpinner,
        ParagraphDirective,
        NotificationBarComponent,
    ],
    templateUrl: './intro-page.component.html',
    styleUrl: './intro-page.component.scss',
})
export class IntroPageComponent implements OnInit {

    public webcamFeed$ = this.faceMatchService.webcamFrames$
    public qualityHint$ = this.faceMatchService.qualityHint$

    constructor(
        private readonly faceMatchService: FaceMatchService,
    ) {
    }

    public async ngOnInit(): Promise<void> {
        await this.faceMatchService.init()
    }

}
