import { isNumber } from './validation.js';
function timeToNumber(clock, time) {
  if (isNumber(time)) {
    // no need to change anything - we want to store numbers anyway
    // we assume this is nanosecond precision
    return time;
  }
  if (time instanceof Date) {
    return clock.convert(time);
  }
  return clock.now();
}
export { timeToNumber };