import { Injectable } from '@angular/core'
import { ApolloError } from '@apollo/client/core'
import {
    CheckInFragment,
    FinishUnattendedCheckInInput,
    FinishUnattendedCheckInMutationService,
    StartUnattendedCheckInInput,
    StartUnattendedCheckInMutationService,
} from '@app/generated/graphql'
import { ErrorService, ErrorType } from '@app/services/error/error.service'
import { path } from 'ramda'
import { firstValueFrom, map, Observable, of, tap } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class UnattendendCheckInService {

    private id: string
    private xDeviceToken: string

    constructor(
        private readonly startUnattendedCheckInMutationService: StartUnattendedCheckInMutationService,
        private readonly finishUnattendedCheckInMutationService: FinishUnattendedCheckInMutationService,
        private readonly errorService: ErrorService,
    ) {
    }

    public setId(id: string) {
        this.id = id
    }

    public getId() {
        return this.id
    }

    public setXDeviceToken(xDeviceToken: string) {
        this.xDeviceToken = xDeviceToken
    }

    public async startUnattendedCheckIn(input: StartUnattendedCheckInInput) {
        try {
            return await firstValueFrom(this.startUnattendedCheckInMutationService.mutate({
                input,
            }, {
                context: {
                    headers: {
                        'X-Device-Token': this.xDeviceToken,
                    },
                },
            }).pipe(
                map(result => result.data?.startUnattendedCheckIn),
                tap((result) => {
                    this.id = result?.id || ''
                }),
            ))
        } catch (error) {
            if (error instanceof ApolloError) {
                if (path(['graphQLErrors', 0, 'extensions', 'validation', 'visitor', 0], error)) {
                    await this.errorService.addError(ErrorType.FORBIDDEN)
                    return
                } else if (path(['graphQLErrors', 0, 'extensions', 'validation', 'input.code', 0], error)) {
                    await this.errorService.addError(ErrorType.RFID)
                    return
                }
            }
        }
        await this.errorService.addError(ErrorType.UNDEFINED)
    }

    public finishUnattendedCheckIn(input: FinishUnattendedCheckInInput)
        : Observable<CheckInFragment | undefined> {
        try {
            return this.finishUnattendedCheckInMutationService.mutate(
                {
                    input,
                }, {
                    context: {
                        headers: {
                            'X-Device-Token': this.xDeviceToken,
                        },
                    },
                }).pipe(map(result => result.data?.finishUnattendedCheckIn))

        } catch (error) {
            return of(undefined)
        }
    }

}
