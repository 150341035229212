import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject } from 'rxjs'

export enum ErrorType {
    RFID = 'rfid',
    IDENTITY = 'identity',
    FORBIDDEN = 'forbidden',
    UNDEFINED = 'undefined',
}

@Injectable({
    providedIn: 'root',
})
export class ErrorService {

    private errorType$$ = new BehaviorSubject<ErrorType>(ErrorType.UNDEFINED)
    public errorType$ = this.errorType$$.asObservable()

    constructor(
        private readonly router: Router,
    ) {
    }

    public async addError(type: ErrorType): Promise<void> {
        this.setErrorType(type)
        await this.router.navigate(['/error'])
    }

    public async clearError(): Promise<void> {
        this.setErrorType(ErrorType.UNDEFINED)
    }

    private setErrorType(type: ErrorType): void {
        this.errorType$$.next(type)
    }

}
