import { ApplicationConfig, inject } from '@angular/core'
import { ApolloClientOptions, InMemoryCache } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { Apollo, APOLLO_OPTIONS } from 'apollo-angular'
import { HttpLink } from 'apollo-angular/http'
import { environment } from '@app-env/environment'

export function apolloOptionsFactory(): ApolloClientOptions<any> {
    const httpLink = inject(HttpLink)

    const authLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
            },
        }
    })

    return {
        link: authLink.concat(
            httpLink.create({
                uri: `${environment.api.schema}://${environment.api.host}/${environment.api.graphqlPath}`,
            }),
        ),
        cache: new InMemoryCache(),
    }
}

export const graphqlProvider: ApplicationConfig['providers'] = [
    Apollo,
    {
        provide: APOLLO_OPTIONS,
        useFactory: apolloOptionsFactory,
    },
]
