import { HttpInterceptorFn } from '@angular/common/http'
import { environment } from '@app-env/environment'

export const codeScannerApiAuthInterceptor: HttpInterceptorFn = (request, next) => {
    if (! request.url.startsWith(environment.codeScanner.endpoint)) {
        return next(request)
    }

    return next(
        request.clone({
            setHeaders: {
                Authorization: `Basic ${btoa(`${environment.codeScanner.username}:${environment.codeScanner.password}`)}`,
            },
        }),
    )
}
