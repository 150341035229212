<div class="page-container">
    <header>
        <img src="/assets/images/queens-casino.svg" alt="Queens Casino" class="logo"/>
    </header>
    <main>
        <ng-content />
    </main>
    <div>
        <div class="warning-banner"></div>
    </div>
</div>