import { Component } from '@angular/core'
import { NgOptimizedImage } from '@angular/common'
import { ParagraphDirective } from '@app-ui/directives'

@Component({
    selector: 'app-default-layout',
    standalone: true,
    imports: [
        NgOptimizedImage,
        ParagraphDirective,
    ],
    templateUrl: './default.layout.component.html',
    styleUrl: './default.layout.component.scss',
})
export class DefaultLayoutComponent {

}
