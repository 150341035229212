import { AsyncPipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { MatIcon } from '@angular/material/icon'
import { Router } from '@angular/router'
import { TimerComponent } from '@app-ui/components/timer/timer.component'
import { ParagraphDirective } from '@app-ui/directives'
import { DefaultLayoutComponent } from '@app-ui/layouts'
import { HeadingDirective } from '@app/domains/ui/directives/heading/heading.directive'
import { TimerLayoutComponent } from '@app/domains/ui/layouts/timer/timer.layout.component'
import { ErrorService, ErrorType } from '@app/services/error/error.service'
import { Observable } from 'rxjs'

@Component({
    selector: 'app-error-rfid',
    standalone: true,
    imports: [
        TimerLayoutComponent,
        HeadingDirective,
        MatIcon,
        AsyncPipe,
        DefaultLayoutComponent,
        ParagraphDirective,
        TimerComponent,
    ],
    templateUrl: './error-page.component.html',
    styleUrl: './error-page.component.scss',
})
export class ErrorPageComponent implements OnInit {

    public errorType$: Observable<ErrorType>

    constructor(
        private readonly router: Router,
        private readonly errorService: ErrorService,
    ) {
    }

    public ngOnInit(): void {
        this.errorType$ = this.errorService.errorType$
    }

    public async returnToWelcome() {
        await this.router.navigate([''])
    }

    protected readonly ErrorType = ErrorType
    protected readonly Error = Error
}
