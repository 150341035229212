<app-default-layout>
    <div class="flex-wrapper">
        <h1
            appHeading
            class="main-heading"
        >
            fast&nbsp;lane
        </h1>
        <mat-icon class="arrow">arrow_downward</mat-icon>
        <div class="text-with-icon" >
            <mat-icon class="icon">
                credit_card
            </mat-icon>
            <p appParagraph isLarge isMedium>Scan uw toegangspas</p>
        </div>
        <div class="text-with-icon" >
            <mat-icon class="icon" >
                info_outline
            </mat-icon>
            <p appParagraph>Staat u nog niet geregistreerd voor de fast&nbsp;lane? Meld u aan de balie.</p>
        </div>

        <div>
            @if (isDevMode()) {
                <div class="button-container">
                    <button
                        appButton
                        isSecondary
                        (click)="simulateCard('a')"
                    >
                        Simulate card A
                    </button>
                    <button
                        appButton
                        isSecondary
                        (click)="simulateCard('b')"
                    >
                        Simulate card B
                    </button>
                    <button
                        appButton
                        isSecondary
                        (click)="simulateCard('c')"

                    >
                        Simulate card C
                    </button>
                </div>
            }
        </div>
    </div>
</app-default-layout>
