import { booleanAttribute, Directive, HostBinding, Input } from '@angular/core'

/**
 * Styles element like a button.
 * Supports Button, Paragraph, Span and a Anchor
 */
@Directive({
    selector: '[appButton]',
    standalone: true,
})
export class ButtonDirective {

    @Input({ transform: booleanAttribute })
    public isSecondary: boolean = false

    @Input({ transform: booleanAttribute })
    public isFull: boolean = false

    /**
     * isDisabled is for Paragraphs spans and Anchors. Buttons can use the 'disabled' attribute
     * @type {boolean}
     */
    @Input()
    public isDisabled: boolean = false

    @HostBinding('class')
    public get classes(): string {
        let classes: Array<string> = []
        classes.push('dir-button')
        if (this.isSecondary) {
            classes.push('dir-button__secondary')
        }
        if (this.isDisabled) {
            classes.push('dir-button__disabled')
        }
        if (this.isFull) {
            classes.push('dir-button__full-width')
        }
        return classes.join(' ')
    }

}
