import { NgStyle } from '@angular/common'
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    inject,
    Input,
    Output,
} from '@angular/core'
import { timer } from 'rxjs'

@Component({
    selector: 'app-timer',
    standalone: true,
    imports: [
        NgStyle,
    ],
    templateUrl: './timer.component.html',
    styleUrl: './timer.component.scss',
})
export class TimerComponent implements AfterViewInit {

    /**
     * Represents the length of the animation in ms.
     */
    @Input()
    public length: number = 10000

    @Output()
    public done = new EventEmitter<Date>()
    public cd = inject(ChangeDetectorRef)
    public progressBarState = 'start'

    public time: number

    public ngAfterViewInit(): void {
        this.progressBarState = 'end'
        this.cd.detectChanges()

        this.time = Math.ceil(this.length / 1000)

        const interval = setInterval(() => {
            this.time--
        }, 1000)

        setTimeout(() => {
            clearInterval(interval)
            this.done.emit(new Date())
        }, this.length)
    }

}
