<app-default-layout>
    <div class="feedback-wrapper">
        <div class="error-heading">
            <mat-icon
                class="error-icon"
            >
                error
            </mat-icon>
            <h1 appHeading>
                @switch (errorType$ | async) {
                    @case (ErrorType.RFID) {
                        De toegangspas is niet herkend
                    }
                    @case (ErrorType.IDENTITY) {
                        Uw identiteit kon niet goed worden vastgesteld
                    }
                    @case (ErrorType.FORBIDDEN) {
                        Uw toegang is geweigerd
                    }
                    @default {
                        Er is iets misgegaan
                    }
                }
            </h1>

            <p appParagraph isLarge>
                @switch (errorType$ | async) {
                    @case (ErrorType.RFID) {
                        Meld u bij de balie als het probleem zich blijft voordoen
                    }
                    @case (ErrorType.IDENTITY) {
                        Meld u bij de balie
                    }
                    @case (ErrorType.FORBIDDEN) {
                        Meld u bij de balie
                    }
                    @default {
                        Meld u bij de balie als het probleem zich blijft voordoen
                    }
                }
            </p>
        </div>
        <div class="timer-container">
            <app-timer [length]="8000" (done)="returnToWelcome()"></app-timer>
            <p appParagraph isLarge>De volgende gast wordt geholpen in</p>
        </div>
    </div>

</app-default-layout>
