<app-default-layout>
    <div class="flex-wrapper">

        @if (webcamFeed$ | async; as webcamFeed) {
            <div class="webcam-feed" [style.background-image]="'url(data:image/jpeg;base64,'+webcamFeed+')'">

            </div>
        } @else {
            <div class="loader">
                <mat-spinner/>
            </div>
        }

        <div class="instructions-container">

            @if (qualityHint$ | async; as qualityHint) {
                <app-notification-bar
                    type="danger"
                >
                    {{ qualityHint }}
                </app-notification-bar>
            } @else {
                <div class="text-with-icon">
                    <img src="/assets/images/svg/arrow_range.svg" class="icon"/>
                    <p appParagraph isLarge isMedium>Houd een passende afstand tot de camera</p>
                </div>
                <div class="text-with-icon">
                    <img src="/assets/images/svg/frame_person.svg" class="icon"/>
                    <p appParagraph isLarge isMedium>Kijk in de camera tot uw gezicht is herkend</p>
                </div>
            }
        </div>
    </div>
</app-default-layout>
